import { useParams, useNavigate } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext';
import React, { useEffect, useState, useContext } from 'react';
import apiService from '../../services/api.service';
import { useErrorDialog } from '../../global/errorContext';
import StatusHistoryWarranty from '../../components/statusHistoryWarranty';
import Coupon from '../../components/coupon';
import Loader from '../../components/loader';
import Big from 'big.js';
import '../../styles/returns.css'; // Adjust the path as per your project structure

const WarrantiesPage = () => {
    const navigate = useNavigate();
    const { orderId, sku } = useParams();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext);
    const [warranties, setWarranties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchWarranties = async () => {
            setIsLoading(true);
            try {
                let warrantiesData;
                if (sku) {
                    const response = await apiService.getWarrantyByOrderIdAndSku(orderId, sku);

                    warrantiesData = response.warranty; // Adjust according to your API response structure
                } else {
                    const response = await apiService.getWarrantiesByOrderAndMobile(userData.phoneNumber, orderId);
                    warrantiesData = response.warranties; // Adjust according to your API response structure
                }
        
                // Handle warrantiesData appropriately based on the new structure
                if (warrantiesData) {
                    setWarranties(Array.isArray(warrantiesData) ? warrantiesData : [warrantiesData]);
                } else {
                    setWarranties([]); // Handle case where no warranties are found
                }
            } catch (error) {
                showError(error.toString()); // Show error message using error context
            } finally {
                setIsLoading(false);
            }
        };

        if (userData.phoneNumber) {
            fetchWarranties();
        } else {
            navigate('/');
        }
    }, [userData.phoneNumber, orderId, sku]);

    return (
        <div className='returns-page'>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <h1 className='title'>حالة الضمان</h1>
                    <div className='returns-list'>
                        {warranties.length > 0 ? (
                            warranties.map((warrantyRequest, index) => (
                                <WarrantyCard key={index} warrantyRequest={warrantyRequest} />
                            ))
                        ) : (
                            <p>لايوجد سجل ضمان للطلب رقم {orderId}</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};




const WarrantyCard = ({ warrantyRequest }) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        calendar: 'gregory',
        numberingSystem: 'latn'
    };

    const products = warrantyRequest.products; // Assuming products is an array of products

    const totalPrice = products.reduce((acc, product) => {
        const pricePerItem = new Big(product.price);
        const quantity = new Big(product.quantity);
        const totalPricePerProduct = pricePerItem.times(quantity);
        return acc.plus(totalPricePerProduct);
    }, Big(0)).toFixed(2); // Calculate total price using Big.js and sum up for all products

    return (
        <div className="return-card">
            <p className="return-date">{new Date(warrantyRequest.createdAt).toLocaleDateString('ar-EG', options)}</p>
            <div className="products-thumbnails">
                {products.map((product, index) => (
                    <img key={index} src={product.thumbnail} alt={`Product ${index + 1}`} />
                ))}
            </div>
            <p className="total-price">Total: {totalPrice} SAR</p>
            <StatusHistoryWarranty currentSlug={warrantyRequest.status.slug} />
            {warrantyRequest.status.slug === 'rejected'&&<p className="warning-message">ملاحظات الطلب: {warrantyRequest.statusHistory[warrantyRequest.statusHistory.length-1].customerNote}</p>}
            {(warrantyRequest.status.slug === 'coupon'||warrantyRequest.status.slug === 'closed') && (
                <Coupon promo={warrantyRequest.coupon.code} value={warrantyRequest.coupon.amount.amount} currency={warrantyRequest.coupon.amount.currency} expire={new Date(new Date(warrantyRequest.coupon.created_at).setMonth(new Date(warrantyRequest.coupon.created_at).getMonth() + 1)).toLocaleDateString('ar-EG', options)}
                />
            )}
        </div>
    );
};

export { WarrantiesPage, WarrantyCard };