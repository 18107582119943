// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-field {
    width: 40px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .otp-field:focus {
    border-color: var(--primary-color); /* Change this to your desired focus border color */
    box-shadow: var(--secondary-color); /* Optional: Add a shadow to enhance the focus effect */
  }`, "",{"version":3,"sources":["webpack://./src/styles/otpField.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,kCAAkC,EAAE,mDAAmD;IACvF,kCAAkC,EAAE,uDAAuD;EAC7F","sourcesContent":[".otp-field {\n    width: 40px;\n    text-align: center;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    outline: none;\n    transition: border-color 0.3s;\n  }\n  \n  .otp-field:focus {\n    border-color: var(--primary-color); /* Change this to your desired focus border color */\n    box-shadow: var(--secondary-color); /* Optional: Add a shadow to enhance the focus effect */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
