import { useParams, useNavigate } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext';
import React, { useEffect, useState, useContext } from 'react';
import apiService from '../../services/api.service';
import { useErrorDialog } from '../../global/errorContext';
import StatusHistoryWarranty from '../../components/statusHistoryWarranty';
import Coupon from '../../components/coupon';
import Loader from '../../components/loader';
import Big from 'big.js';
import '../../styles/returns.css'; // Adjust the path as per your project structure
import { NavLink } from 'react-router-dom';

const WarrantiesPage = () => {
    const navigate = useNavigate();
    const { orderId, sku } = useParams();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext);
    const [warranties, setWarranties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Move fetchWarranties function outside of useEffect
    const fetchWarranties = async () => {
        setIsLoading(true);
        try {
            let warrantiesData;
            if (sku) {
                const response = await apiService.getWarrantyByOrderIdAndSku(orderId, sku);
                warrantiesData = response.warranties; // Adjust according to your API response structure
            } else {
                const response = await apiService.getWarrantiesByOrderAndMobile(userData.phoneNumber, orderId);
                warrantiesData = response.warranties; // Adjust according to your API response structure
            }

            // Handle warrantiesData appropriately based on the new structure
            if (warrantiesData) {
                setWarranties(Array.isArray(warrantiesData) ? warrantiesData : [warrantiesData]);
            } else {
                setWarranties([]); // Handle case where no warranties are found
            }
        } catch (error) {
            showError(error.toString()); // Show error message using error context
        } finally {
            setIsLoading(false);
        }
    };

   

    useEffect(() => {
        if (userData.phoneNumber) {
            fetchWarranties();
        } else {
            navigate('/');
        }
    }, [userData.phoneNumber, orderId, sku]); // Keep the effect dependencies to trigger when necessary

    return (
        <div className='returns-page'>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <h1 className='title'>حالة الضمان</h1>
                    <div className='returns-list'>
                        {warranties.length > 0 ? (
                            warranties.map((warrantyRequest, index) => (
                                <WarrantyCard
                                    key={index}
                                    warrantyRequest={warrantyRequest}
                                />
                            ))
                        ) : (
                            <p>لايوجد سجل ضمان للطلب رقم {orderId}</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const WarrantyCard = ({ warrantyRequest, activateCoupon }) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        calendar: 'gregory',
        numberingSystem: 'latn'
    };
    const keywords = ['صورة', 'فيديو'];


    const products = warrantyRequest.products; // Assuming products is an array of products
    const withVatValue = (warrantyRequest.coupon?.amount?.amount * 1.15).toFixed(2);

    const totalPrice = products.reduce((acc, product) => {
        const pricePerItem = new Big(product.price);
        const quantity = new Big(product.quantity);
        const totalPricePerProduct = pricePerItem.times(quantity);
        return acc.plus(totalPricePerProduct);
    }, Big(0)).toFixed(2); // Calculate total price using Big.js and sum up for all products
    const hasAcceptedProducts = warrantyRequest.acceptedProducts && warrantyRequest.acceptedProducts.length > 0;

    return (
        <div className="return-card">
            <p className="return-date">{new Date(warrantyRequest.createdAt).toLocaleDateString('ar-EG', options)}</p>
            <div className="products-thumbnails">
                {products.map((product, index) => (
                    <img key={index} src={product.thumbnail} alt={`Product ${index + 1}`} />
                ))}
            </div>
            <p className="total-price">Total: {totalPrice} SAR</p>
            <StatusHistoryWarranty currentSlug={warrantyRequest.status.slug}  partial={hasAcceptedProducts}/>

            { (["rejected", "review"].includes(warrantyRequest.status.slug)) && (
                <div>
                    <p className="warning-message">
                        ملاحظات الطلب: {warrantyRequest.statusHistory[warrantyRequest.statusHistory.length - 1].customerNote || warrantyRequest.statusHistory[warrantyRequest.statusHistory.length - 1].note}
                    </p>

                </div>
            )}
            {hasAcceptedProducts && (
                <div className="accepted-products-container">
                    <p className="heading">المنتجات المقبولة:</p>
                    <table className="product-table">
                    <thead>
                        <tr>
                        <th>رمز المنتج</th>
                        <th>الكمية</th> {/* Updated title to "الكمية" */}
                        </tr>
                    </thead>
                    <tbody>
                        {warrantyRequest.acceptedProducts.map((product, index) => (
                        <tr key={index}>
                            <td className="product-sku">{product.sku}</td>
                            <td className="product-quantity">{product.quantity}</td> {/* Updated quantity display */}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                )}

              <p>
                {(
                    (["rejected", "review"].includes(warrantyRequest.status.slug)) && 
                    keywords.some(keyword => (warrantyRequest.statusHistory[warrantyRequest.statusHistory.length - 1]).note.includes((keyword)))
                ) && (
                    <NavLink to={`/warranty-edit-img/${warrantyRequest._id}`} className="edit-link">
                    تعديل الطلب
                    </NavLink>
                )}
                </p>


            {(warrantyRequest.status.slug === 'coupon' || warrantyRequest.status.slug === 'closed') && (
                <>
                    <Coupon
                        promo={warrantyRequest.coupon.code}
                        status={warrantyRequest.coupon.status}
                        value={warrantyRequest.coupon.amount.amount}
                        currency={warrantyRequest.coupon.amount.currency}
                        withVatvalue={withVatValue}
                        expire={new Date(new Date(warrantyRequest.coupon.created_at).setMonth(new Date(warrantyRequest.coupon.created_at).getMonth() + 1)).toLocaleDateString('ar-EG', options)}
                    />
                </>
            )}
        </div>
    );
};

export { WarrantiesPage, WarrantyCard };
