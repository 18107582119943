// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-bg{
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 50vh; /* Take full viewport height */
  background-color: #f8f8f8; /* Light gray background */
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid var(--primary-color); /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .loader-bg {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the loader and message */
}

.loader-message {
    margin-top: 10px; /* Space between loader and message */
    font-size: 16px; /* Adjust font size as needed */
    color: #333; /* Color for the message */
    text-align: center; /* Center align the message */
}`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,8BAA8B;EAC7C,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,YAAY,EAAE,8BAA8B;EAC5C,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;IACI,yBAAyB,EAAE,eAAe;IAC1C,0CAA0C,EAAE,SAAS;IACrD,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAE,kCAAkC;AAC3D;;AAEA;IACI,gBAAgB,EAAE,qCAAqC;IACvD,eAAe,EAAE,+BAA+B;IAChD,WAAW,EAAE,0BAA0B;IACvC,kBAAkB,EAAE,6BAA6B;AACrD","sourcesContent":[".loader-bg{\n  display: flex; /* Use flexbox for centering */\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  height: 50vh; /* Take full viewport height */\n  background-color: #f8f8f8; /* Light gray background */\n}\n\n.loader {\n    border: 4px solid #f3f3f3; /* Light grey */\n    border-top: 4px solid var(--primary-color); /* Blue */\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 2s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  .loader-bg {\n    display: flex;\n    flex-direction: column;\n    align-items: center; /* Center the loader and message */\n}\n\n.loader-message {\n    margin-top: 10px; /* Space between loader and message */\n    font-size: 16px; /* Adjust font size as needed */\n    color: #333; /* Color for the message */\n    text-align: center; /* Center align the message */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
