import '../../styles/terms.css';

const TermsPage = () => {
    return <div className="terms-page">
        <h2>شروط و أحكام نظام الإرجاع:</h2>
        <p>

<br></br>
في زايروس نحن فخورين ان نقدم نظام يؤمن لعملائنا الراحة التامة لإرجاع المنتجات المطلوبة.

وحرصاً منا على سلامتكم فإننا نوضح لكم شروط واحكام الاستبدال والاسترجاع:

<br></br>
<br></br>


<strong>الاستبدال/</strong>
<br></br>
<br></br>

المنتج المتضرر:

في حال تم استلام المنتج متضرر فإن زايروس تتحمل كامل رسوم الشحن بشرط ألا يكون قد تم استخدام المنتج وأن يبقى على حالته التالفة.

في حال تضرر المنتج أثناء الاستخدام فإن زايروس تضمن لك استبدال المنتج خلال فترة الضمان على أن لا يكون الضرر ناتج عن سوء استخدام. ( كالخدش والكسر والقطع )

<br></br>
<br></br>

<strong>الاسترجاع/</strong>
<br></br>
<br></br>

المنتج بحالته الأصلية:
<br></br>


1- عند استرجاع أي منتج غير تالف أي بحالته الأصلية وكان العميل غير راض عن المنتجات يستطيع الإرجاع خلال 3 أيام من تاريخ الإستلام للشحنات المحلية علماً أن رسوم شحن الإرجاع مجانية ويتم إعادة قيمة المنتج فقط
<br></br>


2- عند استرجاع أي منتج غير تالف أي بحالته الأصلية وكان العميل غير راض عن المنتجات يستطيع الإرجاع خلال 3 أيام من تاريخ استلام الطلب ويتحمل العميل رسوم شحن الإرجاع الدولي وتخصم من قيمة المنتج
<br></br>


تتم إعادة المبالغ للشحنات الدولية خلال 14 يوم فقط من تاريخ الشراء ، وفي حال كانت رغبة العميل بإلغاء الطلب أو إستعادة المنتج لابد من أن يتم إبلاغ خدمة العملاء قبل تجاوز المدة المذكورة .

في حال تم إلغاء الطلب للشحنات الدولية من قبل العميل أو اعادة المنتج بعد تجاوز 14 يوم من تاريخ الشراء يتم عمل كوبون خصم ثابت للعميل بنفس قيمة الشحنة بعد خصم أجور شحن الإرجاع ويمكنه إعادة الطلب من جديد .

<br></br>

3- يجب ان تكون المنتجات المسترجعة بحالتها الجديدة سليمه وجديده وبغلافها الأصلي.

<br></br>

4- يتم إرسال بوليصة الاسترجاع ويقوم العميل بتسليم البوليصة مع المنتجات لأقرب فرع شركة شحن معتمده لدينا ، ليتم إعادة المنتجات إلى مستودعاتنا واكمال اجراءات الارجاع.

<br></br>

5- رسوم شحن الطلب غير مستردة في حال الإرجاع

<br></br>

6- رسوم خدمة الدفع عند الإستلام غير مستردة عند الإرجاع

<br></br>
<br></br>

المدة المحددة للاستبدال والاسترجاع:
<br></br>
1- إن زايروس تمكنكم من إرجاع المنتج بحالته الأصلية خلال 3 أيام من تاريخ استلام الشحنة.
<br></br>


2- إذا كانت طريقة الدفع لطلبك عن طريق بطاقة الائتمان أو التحويل البنكي يمكنك إرجاع المنتجات إلى زايروس وبعد وصول المنتج إلينا والتأكد من حالته يتم استرداد المبلغ مع العلم قد تستغرق عملية استرجاع المبلغ لحسابكم البنكي (14) يوم عمل في حال كان الدفع بنكي أو استلام، وفي حال كان الدفع عبر بطاقة ائتمانية فإن عملية استرداد المبلغ تستغرق (20) يوم.

<br></br>

3- حفاظا على حق عملائنا الكرام نود افادتكم بأنه في حال تم الغاء الطلب من قبل العميل او رغبة العميل برفض استلام الشحنة يتم إعادة المبلغ للطلب المرتجع خلال 14 يوم كحد أقصى من تاريخ إستلامنا المرتجع من شركة الشحن 

</p>
    </div>
};

export default TermsPage;