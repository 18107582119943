// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-page{
    direction: rtl;
    width: 70%;            /* Set a specific width less than 100% */
    margin: 20px auto;     /* Top and bottom margins 20px, left and right margins auto */
    display: block;        /* Ensures the container is treated as a block-level element */
}`, "",{"version":3,"sources":["webpack://./src/styles/terms.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,UAAU,aAAa,wCAAwC;IAC/D,iBAAiB,MAAM,6DAA6D;IACpF,cAAc,SAAS,8DAA8D;AACzF","sourcesContent":[".terms-page{\n    direction: rtl;\n    width: 70%;            /* Set a specific width less than 100% */\n    margin: 20px auto;     /* Top and bottom margins 20px, left and right margins auto */\n    display: block;        /* Ensures the container is treated as a block-level element */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
