import React from 'react';
import NavBar from './components/navBar'
import {Routes, Route } from 'react-router-dom'; // Import necessary components from react-router-dom
import './styles/App.css';
import UserDataProvider from './session/UserDataProvider';
import { ErrorDialogProvider } from './global/errorContext';
import ErrorDialog from './components/errorDialog'; // Ensure this import is correct

import LoginPage from './pages/login';
import OTPPage from './pages/otp'; 
import OrdersPage from './pages/return/orders'; 
import OrderDetailsPage from './pages/return/orderDetails'; 
import ReturnsPage from './pages/return/returns'; 
import ThankYouPage from './pages/return/confirmation'; 
import ThankYouWarrantyPage from './pages/warranty/confirmation'; 

import TermsPage from './pages/return/terms'; 
import SetFavicon from './components/setFavicon';


import SystemSelect from './pages/systemSelect';
import OrdersWarrantyPage from './pages/warranty/orders';
import OrderDetailsWarrantyPage from './pages/warranty/orderDetails';
import {WarrantiesPage} from './pages/warranty/warranties';
import WarrantyTermsPage from './pages/warranty/terms'
import Header from './components/header';
import './utils/logger'

function App() {
  return (
      <div>
        <SetFavicon />
        <NavBar />
        <Header />       
         <UserDataProvider>
          <ErrorDialogProvider>
          <ErrorDialog />
        <Routes> {/* Define your routes within Routes component */}
          <Route path="/:system/login" element={<LoginPage />} /> {/* Define the route for LoginPage */}
          <Route path="/:system/otp" element={<OTPPage />} /> {/* Define the route for OrdersPage */}
          <Route path="return/orders" element={<OrdersPage />} /> {/* Define the route for OrdersPage */}
          <Route path="/order/:orderId" element={<OrderDetailsPage />} />
          <Route path="/returns/:orderId" element={<ReturnsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/thank-you-warranty" element={<ThankYouWarrantyPage />} />
          <Route path="/terms-conditions" element={<TermsPage />} />
          {/* You can add more routes here as needed */}
          <Route path="/" element={<SystemSelect />} />
          <Route path="/warranty/orders" element={<OrdersWarrantyPage />} />
          <Route path="/orderWarranty/:orderId" element={<OrderDetailsWarrantyPage />} />
          <Route path="/warranties/:orderId/:sku?" element={<WarrantiesPage />} />
          <Route path="/warranty-terms" element={<WarrantyTermsPage />} />




        </Routes>
        </ErrorDialogProvider>
        </UserDataProvider>
      </div>
  );
}

export default App;
