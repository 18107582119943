// OTPPage.js
import React, { useEffect, useState, useContext, useRef } from 'react';
import OTPField from '../components/otpField';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import UserDataContext from '../session/UserDataContext'; // Import the context
import '../styles/otp.css';
import apiService from '../services/api.service';
import { useErrorDialog } from '../global/errorContext';
import brands from '../brands.json';

const OTPPage = () => {
    const navigate = useNavigate();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext); // Access userData from context
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const location = useLocation();
    const { otpResponse } = location.state || {}; // Default to an empty object if state is undefined
    const hiddenInputRef = useRef(null);
    const brandPhone = brands[process.env.REACT_APP_BRAND].phone;
    const { system } = useParams();

    useEffect(() => {
        // Reset otp state to empty
        setOtp(new Array(4).fill(''));

        if (!userData.phoneNumber) {
            navigate('/'); // Adjust as needed
        }

        if (hiddenInputRef.current) {
            hiddenInputRef.current.addEventListener('input', handleAutofill);
        }
        return () => {
            if (hiddenInputRef.current) {
                hiddenInputRef.current.removeEventListener('input', handleAutofill);
            }
        };

    }, [userData.phoneNumber, navigate, system]);


    const handleAutofill = (e) => {
        const value = e.target.value;
        if (value.length === 4 && /^\d*$/.test(value)) {
            setOtp(value.split(''));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check if the otp field is empty - then print a message
        //----------------------------------//

        // Add submission logic here (e.g., verify OTP)
        // Add your login logic here, such as sending credentials to the server
        try {
            const response = await apiService.verifyOTP({ id: otpResponse.id, otp: otp.join('') })
            // Handle navigation to orders page with received orders
            //navigate based on the selected system
            navigate(`/${system}/orders`);
        } catch (error) {
            showError(error.toString());
        }
    };

    return (
        <div className='otp-container'>
            <form onSubmit={handleSubmit} className='otp-form'>
                <div className='otp-title'>
                    <h1>أدخل رمز التحقق</h1>
                    <p>تم إرسال رمز التحقق إلى جوال رقم </p>
                    <p>+{userData.phoneNumber}</p>
                </div>
                <div className="otp-fields">
                    <input
                        type="text"
                        ref={hiddenInputRef}
                        style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
                        autoComplete="one-time-code"
                        tabIndex="-1"
                    />
                    {Array.from({ length: 4 }).map((_, index) => (
                        <OTPField key={index} index={index} otp={otp} setOtp={setOtp} />
                    ))}
                </div>
                {/* Disable submit button of the otp field not filled 4 digits */}
                <button type="submit" disabled={otp.includes('')}>التحقق</button>
                <p className="no-otp-msg">إذا لم يصل رمز التفعيل الى رقم الجوال المدون أعلاه نرجوا منك التكرم بالتواصل مع فريق الدعم من خلال الواتساب على الرقم ( {brandPhone} ) </p>
            </form>
        </div>
    );
};

export default OTPPage;
