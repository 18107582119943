import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  let heading;
  let link = '';
  
 // Define headings and links based on the current path
 if (location.pathname.toLowerCase().includes('warranty') || location.pathname.includes('warranties')) {
  heading = 'منصة الضمان'; // Warranty platform
  link = '/warranty/orders'; // Link for the warranty orders
} else if (location.pathname === '/') {  // Explicitly check for root path '/'
  heading = 'منصة الإرجاع والضمان'; // Keep empty heading for systemSelect case
  link = '/'; // Link for the root
} else {
  heading = 'منصة إرجاع الطلبات'; // General return orders platform
  link = 'return/orders'; // Link for general orders
}

return (
  <h3>
    <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
      {heading}
    </Link>
  </h3>
);
};

export default Header;