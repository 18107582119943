import React from 'react';
import brands from '../brands.json';
import '../styles/coupon.css';

const Coupon = ({ promo, value, currency, expire }) => {
  const logoPath = `/images/${brands[process.env.REACT_APP_BRAND].logo}`;

  return (
    <div className="coupon">
        <div className="container">
        <div className="header">
            <h3>{process.env.REACT_APP_BRAND}</h3>
        </div>
            <img src={logoPath} alt="Logo" />
            <div className="content">
            <h2 className="promo">{promo}</h2>
            <p>مبلغ الخصم: <strong><span >{value}</span><span>{currency=="SAR"&&" ر.س "}</span></strong></p>
            <p className="expire">تنتهي الصلاحية في: {expire}</p>
            </div>
      </div>
    </div>
  );
};

export default Coupon;
