import React from 'react';
import { useWarningDialog } from '../global/WarningDialogContext';
//import React from 'react';
import '../styles/warningDialog.css';

const WarningDialog = () => {
    const { isVisible, message, confirmText, cancelText, onConfirm, hideWarning } = useWarningDialog();
  
    if (!isVisible) return null;
  
    return (
      <>
        <div className="warning-backdrop" onClick={hideWarning}></div>
        <div className="warning-dialog">
          <h2>تحذير</h2>
          <p>{message}</p>
          <div className="dialog-buttons">
            <button className="cancel-button" onClick={hideWarning}>{cancelText}</button>
            <button className="ok-button" onClick={onConfirm}>{confirmText}</button>
          </div>
        </div>
      </>
    );
  };
  
  export default WarningDialog;