import '../../styles/terms.css';

const WarrantyTermsPage = () => {
    return <div className="terms-page">
        <h2>شروط و أحكام نظام الضمان:</h2>
        <p>

<br></br>
في زايروس نحن فخورين ان نقدم لعملائنا ضمان .

وحرصاً منا على سلامتكم فإننا نوضح لكم شروط واحكام الضمان

<br></br>
<br></br>


<strong>سياسة الضمان/</strong>
<br></br>
<br></br>


نحن في زايروس نضمن لكم منتجاتنا لمدة 3 سنوات من تاريخ بيع المنتج والتي تتحقق فيه شروط سياسة الضمان المذكورة أدناه:

<br></br>
<br></br>

1 - يشترط لقبول الضمان توفر رقم الطلب المذكور في رسالة إتمام الطلب أو الفاتورة.

<br></br>
<br></br>

2- يشمل ضمان المنتجات المبيعة من موقع زايروس على جميع العيوب المصنعية.

<br></br>
<br></br>

3 - لا يشمل الضمان على الساعات أو المنتجات في حال سوء الاستخدام وعلى سبيل المثال (القطع المتعمد أو الخدش أو الكسر) ويسقط عنها الضمان.

<br></br>
<br></br>

4 - يشمل ضمان الساعات المبيعة من موقع زايروس على جميع العيوب المصنعية وعلى سبيل المثال (دخول الماء أو عطل المكينة أو تغير اللون).

<br></br>
<br></br>

5 - في حال استلام العميل للمنتج المستبدل بالضمان وكان لديه الرغبة باستبداله بمنتج أخر يتحمل العميل رسوم إعادة المنتج المستبدل ورسوم إرسال شحن المنتج الجديد المتمثلة فيه مبلغ وقدره (ستة وثلاثين ريال سعودي) (44 ريال سعودي) وذلك خلال 24 ساعة من تاريخ استلامه المنتج.

<br></br>
<br></br>

6 - يلزم توفر المنتج المتضرر للاستفادة من خدمة الضمان ويحق لزايروس استرداد المنتج المتضرر من العميل لفحص جودته وفي حال عدم توفر المنتج المتضرر يسقط عنه الضمان. 

<br></br>
<br></br>

7 - لا يحق للعميل المطالبة باسترداد قيمة المنتج نقداً أو جزء منه في حالة التعويض للمنتج المتضرر ، وبعد تجاوز مدة الاسترجاع تطبق في هذه الحالة شروط الضمان أو الصيانة الواردة في السياسة.

<br></br>
<br></br>

8 - يسري الضمان على ذات النوع للمنتج المتضرر، وفي حال عدم توفر ذات المنتج يحق للعميل اختيار منتج أخر بذات القيمة وذات النوع، وفي حال تم اختيار منتج بقيمة أعلى من المنتج المتضرر يلزم على العميل دفع الفرق.

<br></br>
<br></br>

9 - لا تلتزم زايروس بضمان منتجاتها المبيعة من مواقع أخرى غير الموقع الرسمي ، ولكن يتم توفير خدمة الصيانة في هذه الحالة ويتحمل العميل رسوم الصيانة.

<br></br>
<br></br>

10 - لا يسري الضمان على الهدايا التي يتم تقدميها من موقع زايروس للعملاء وعلى سبيل المثال (جوائز السحوبات أو المنتجات المجانية التي توزع في الموقع).

<br></br>
<br></br>

11 - يسري ضمان المنتجات التي يتم شراؤها من الموقع من خلال الموقع الالكتروني فقط مع توفر رقم الطلب.

<br></br>
<br></br>

12- تُطبق سياسة المنشأة والشروط والأحكام الموضحة في الموقع والتي يلزم الاطلاع عليها قبل إجراء أي عمليه شراء من قبل العملاء.


<br></br>
<br></br>


</p>
    </div>
};

export default WarrantyTermsPage;