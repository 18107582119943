// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NavigationBar.css */

.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    color: #333;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-logo img {
    height: auto;
    max-height: 80px;
    width: auto; /* Adjust the width of the logo */
    max-width: 200px;
    order: 1;
}

/* Mobile view styles */
@media (max-width: 768px) { /* Adjust the max-width value based on your breakpoints */
    .navbar-logo img {
        max-height: 60px; /* Smaller height for mobile */
        max-width: 150px; /* Smaller width for mobile */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/navBar.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,WAAW,EAAE,iCAAiC;IAC9C,gBAAgB;IAChB,QAAQ;AACZ;;AAEA,uBAAuB;AACvB,4BAA4B,yDAAyD;IACjF;QACI,gBAAgB,EAAE,8BAA8B;QAChD,gBAAgB,EAAE,6BAA6B;IACnD;AACJ","sourcesContent":["/* NavigationBar.css */\n\n.navbar {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    background-color: white;\n    color: #333;\n    padding: 1rem;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.navbar-logo img {\n    height: auto;\n    max-height: 80px;\n    width: auto; /* Adjust the width of the logo */\n    max-width: 200px;\n    order: 1;\n}\n\n/* Mobile view styles */\n@media (max-width: 768px) { /* Adjust the max-width value based on your breakpoints */\n    .navbar-logo img {\n        max-height: 60px; /* Smaller height for mobile */\n        max-width: 150px; /* Smaller width for mobile */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
