// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* This will center the form vertically */
    margin: auto;
}

.login-form {
    width: 300px; /* Set the desired width of the form */
    padding: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

/* Assuming your button is inside a form with the class "login-form" */
.login-form button {
    width: 100%; /* Set the button width to 100% of its parent container */
    background-color: var(--primary-color);
    border: 1px solid var(--secondary-color);
    color: white;
    height: 40px;
    margin-top: 15px;
    font-size: medium;
    font-weight: bold;
    }

.phone-field {
    width: 100%; /* Set the button width to 100% of its parent container */
}

/* login.css */
.spinner {
    border: 2px solid white;
    border-left-color: var(--secondary-color);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAE,yCAAyC;IACvD,YAAY;AAChB;;AAEA;IACI,YAAY,EAAE,sCAAsC;IACpD,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA,sEAAsE;AACtE;IACI,WAAW,EAAE,yDAAyD;IACtE,sCAAsC;IACtC,wCAAwC;IACxC,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB;;AAEJ;IACI,WAAW,EAAE,yDAAyD;AAC1E;;AAEA,cAAc;AACd;IACI,uBAAuB;IACvB,yCAAyC;IACzC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Login.css */\n.login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50vh; /* This will center the form vertically */\n    margin: auto;\n}\n\n.login-form {\n    width: 300px; /* Set the desired width of the form */\n    padding: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n}\n\n/* Assuming your button is inside a form with the class \"login-form\" */\n.login-form button {\n    width: 100%; /* Set the button width to 100% of its parent container */\n    background-color: var(--primary-color);\n    border: 1px solid var(--secondary-color);\n    color: white;\n    height: 40px;\n    margin-top: 15px;\n    font-size: medium;\n    font-weight: bold;\n    }\n\n.phone-field {\n    width: 100%; /* Set the button width to 100% of its parent container */\n}\n\n/* login.css */\n.spinner {\n    border: 2px solid white;\n    border-left-color: var(--secondary-color);\n    border-radius: 50%;\n    width: 16px;\n    height: 16px;\n    animation: spin 1s linear infinite;\n    display: inline-block;\n    vertical-align: middle;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
