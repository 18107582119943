import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import brands from './brands.json';
//this is the intry point for the frontend part -> App.js


const root = ReactDOM.createRoot(document.getElementById('root'));
const brandName = process.env.REACT_APP_BRAND; // Make sure to set this in your .env file
setBrandColors(brandName);

function setBrandColors(brandName) {
  const root = document.documentElement;
  const brand = brands[brandName];
  root.style.setProperty('--primary-color', brand.primaryColor);
  root.style.setProperty('--secondary-color', brand.secondaryColor);
  root.style.setProperty('--highlightColor', brand.highlightColor);
}

root.render(
     <BrowserRouter> {/* Wrap App component with BrowserRouter */}
    <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
