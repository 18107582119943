import React, { createContext, useContext, useState } from 'react';

// Create the context
const ErrorDialogContext = createContext();

// Provider component that wraps your app and makes an error dialog available everywhere
export const ErrorDialogProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');

  const showError = (msg) => {
    setMessage(msg);
    setIsVisible(true);
  };

  const hideError = () => {
    setIsVisible(false);
    setMessage('');
  };

  return (
    <ErrorDialogContext.Provider value={{ isVisible, message, showError, hideError }}>
      {children}
    </ErrorDialogContext.Provider>
  );
};

// Custom hook to use the error dialog context
export const useErrorDialog = () => useContext(ErrorDialogContext);
