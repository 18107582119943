// WarningDialogContext.js
import React, { createContext, useContext, useState } from 'react';

const WarningDialogContext = createContext();

export const WarningDialogProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [confirmText, setConfirmText] = useState('متابعة');
  const [cancelText, setCancelText] = useState('إلغاء');

  const showWarning = ({ msg, onConfirmAction, confirmLabel, cancelLabel }) => {
    setMessage(msg);
    setConfirmText(confirmLabel || 'متابعة');
    setCancelText(cancelLabel || 'إلغاء');
    setOnConfirm(() => () => {
      onConfirmAction?.();
      hideWarning();
    });
    setIsVisible(true);
  };

  const hideWarning = () => {
    setIsVisible(false);
    setMessage('');
    setOnConfirm(() => () => {});
  };

  return (
    <WarningDialogContext.Provider
      value={{ isVisible, message, confirmText, cancelText, onConfirm, showWarning, hideWarning }}>
      {children}
    </WarningDialogContext.Provider>
  );
};

export const useWarningDialog = () => useContext(WarningDialogContext);