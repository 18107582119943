import { Link, useNavigate } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext';
import React, { useEffect, useState, useContext } from 'react';
import apiService from '../../services/api.service';
import { useErrorDialog } from '../../global/errorContext';
import Loader from '../../components/loader';
import '../../styles/orders.css';
import brands from '../../brands.json';

const OrdersWarrantyPage = () => {
    const navigate = useNavigate();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext); 
    const [orders, setOrders] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState(''); // State for loading message
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [totalPages, setTotalPages] = useState(1);  // Total number of pages
    const [searchInput, setSearchInput] = useState('');  // Track search input


    const options = {
        weekday: 'long', 
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        calendar: 'gregory',
        numberingSystem: 'latn'
    };

    // Fetch orders with the current page number
    const fetchOrders = async (page = 1, orderId = "") => {
        setLoadingMessage('...جارٍ جلب طلباتك')
        setIsLoading(true);
        try {
            const fetchedOrders = await apiService.getOrders(userData.phoneNumber,orderId, page);  // Pass page number
            setOrders(fetchedOrders?.orders);
            setTotalPages(fetchedOrders?.pagination?.totalPages);  // Update total pages
            sessionStorage.setItem('orders', JSON.stringify(fetchedOrders?.data));
        } catch (error) {
            showError(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    // Re-fetch orders when the phone number or current page changes
    useEffect(() => {
        if (userData.phoneNumber) {
            const savedOrders = sessionStorage.getItem('orders');
            if (savedOrders && savedOrders !== "undefined") {
                try {
                    const parsedOrders = JSON.parse(savedOrders);
                    setOrders(parsedOrders);
                } catch (error) {
                    console.error("Failed to parse orders from sessionStorage:", error);
                    fetchOrders(currentPage);  // Fetch with current page
                }
            } else {
                fetchOrders(currentPage);  // Fetch orders if no stored data
            }
        } else {
            navigate('/');
        }
    }, [userData.phoneNumber, currentPage]);

    const handleRefreshOrders = async () => {
        try {
            setIsLoading(true);
            setLoadingMessage('...جارٍ تحديث طلباتك')
            const refreshedOrders = await apiService.fetchSallaOrdersForCustomer(userData.phoneNumber, brands[process.env.REACT_APP_BRAND].merchantId);
            if (refreshedOrders?.orders) {
                fetchOrders(currentPage);  // Refresh orders for the current page
            } else {
                throw new Error('حدث خطأ في تحديث الطلبات');
            }
        } catch (error) {
            showError(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    // Handle changing pages
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const  handleSearchOrder = async () => {
        await fetchOrders(1,searchInput);
   
    };

    return (
        <div className='ordersPage'>
            {isLoading ? (
                <Loader message={loadingMessage}/>
            ) : (
                <>
                    <div className='banner'></div>
                    <h1 className='title'>الطلبات</h1>
                    <div className="table-control">
                    <div>
                            <button onClick={handleRefreshOrders} className="refresh-button"><i class="fas fa-sync-alt"></i><span className="showRefreshText"> تحديث الطلبات</span></button>
                        </div>
                    
                        <div className="search-container">
                            <input
                                type="text"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                placeholder="ابحث برقم الطلب"
                                className="search-bar"
                            />
                            <button
                                onClick={() => handleSearchOrder()}
                                className="search-button"
                            >
                                بحث
                            </button>
                        </div>
                        
                    </div>
                    
                    <div className='ordersTableContainer'>
                        {orders && orders.length > 0 ? (
                            <>
                                <table className='s-table'>
                                    <thead>
                                        <tr>
                                            <th>رقم الطلب</th>
                                            <th>المجموع</th>
                                            <th>تاريخ الطلب</th>
                                            <th>حالة الطلب</th>
                                            <th>تاريخ التوصيل</th>
                                            <th>سجل طلبات الضمان</th>
                                            <th>الضمان</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list-container">
                                        {orders.map((order, index) => (
                                            <tr key={index} className="s-table__tr text-store-text-primary">
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">رقم الطلب:</span>
                                                        #{order.order_ref}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">المجموع:</span>
                                                        <div className="total" >{order.total} SAR</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">تاريخ الطلب:</span>
                                                        {new Date(order.created_at).toLocaleDateString('ar-EG', options)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">حالة الطلب:</span>
                                                        <span style={{ color: "#111111" }}>
                                                            <span>{order.status.name}</span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span className="mobile-title">تاريخ التوصيل:</span>
                                                        {order.delivered_at ? (new Date(order.delivered_at).toLocaleDateString('ar-EG', options)) : '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={`/warranties/${order.order_ref}`}>السجل</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    {(order.status.slug === 'delivered' || order.status.slug === 'restoring' || order.status.slug === 'shipped') ? (
                                                        <button className='return-button' onClick={() => navigate(`/orderWarranty/${order.order_ref}`)}>بدء الضمان</button>
                                                    ) : (
                                                        <button className='return-button' disabled>غير متاح</button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Pagination Controls */}
                                <div className="pagination">
                                    <button
                                        className="pagination-button"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        السابق
                                    </button>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <button
                                            key={i + 1}
                                            className={`pagination-button ${i + 1 === currentPage ? 'active' : ''}`}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                    <button
                                        className="pagination-button"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        التالي
                                    </button>
                                </div>

                                <p>يرجى التواصل مع فريق خدمة العملاء في حال رغبتكم بتعديل طلب ضمان سابق  </p>
                            </>
                        ) : (
                            <p>لايوجد طلبات</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default OrdersWarrantyPage;
