//import React from 'react';
import '../styles/bankForm.css';

// BankDetailsForm.js
import React from 'react';

const BankDetailsForm = ({ bankDetails, handleBankChange }) => {
  return (
    <div className='bank-details-form'>
      <h2>البيانات البنكية</h2>
      <p>عزيزنا العميل، نرجو منك تزويدنا بالبيانات البنكية التالية:</p>
      <p className="required-field">اسم صاحب الحساب</p>
      <input
        type="text"
        name="accountOwner"
        required
        value={bankDetails.accountOwner}
        onChange={handleBankChange}
        placeholder='الاسم مطابق للحساب البنكي'
        title="اسم صاحب الحساب مطلوب."
      />
      <p className="required-field">اسم البنك</p>
      <input
        type="text"
        name="bankName"
        required
        value={bankDetails.bankName}
        onChange={handleBankChange}
        placeholder='بنك الخليج الدولي'
        title="اسم البنك مطلوب."
      />
      <p className="required-field">رقم الأيبان</p>
      <input
        type="text"
        name="Iban"
        required
        value={bankDetails.Iban}
        onChange={handleBankChange}
        placeholder='SA0000000000000000000000'
        pattern="SA[a-zA-Z0-9]{22}"
        title="الرجاء إدخال رقم أيبان صالح."
       // maxLength="24"
      />
    </div>
  );
};

export default BankDetailsForm;
