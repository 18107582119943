// Function to extract order ID from the input string
function extractOrderId(input) {
    // Regular expression to match the desired format
    const regex = /^[A-Za-z]{2}(\d{9})[A-Za-z0-9]{2}$/;
    const match = input.match(regex);

    if (match && match[1]) {
        return match[1]; // Return the captured group (order ID)
    } else {
        throw new Error("Invalid input format");
    }
}

// Export the function for external usage (Node.js)
module.exports = extractOrderId;