import { useEffect } from 'react';
import brands from '../brands.json';

const SetFavicon = () => {
  useEffect(() => {
    const brandName = process.env.REACT_APP_BRAND;
    const logo = brands[brandName]?.logo;

    if (logo) {
     const logoPath = window.location.origin + "/images/" + logo;
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = logoPath;
      document.head.appendChild(link);

      // Set apple-touch-icon
      const appleTouchIcon = document.createElement('link');
      appleTouchIcon.rel = 'apple-touch-icon';
      appleTouchIcon.href = logoPath;
      document.head.appendChild(appleTouchIcon);

      // Update og:image
      let ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.setAttribute('content', logoPath);

      } else {
        ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.setAttribute('content', logoPath);
        document.head.appendChild(ogImage);
      }

    } else {
      console.error('Brand logo path not found.');
    }
  }, []);

  return null;
};

export default SetFavicon;
