//import React from 'react';
import '../styles/loader.css';

// BankDetailsForm.js
import React from 'react';

const Loader = ({ message = '' }) => {
    return (
        <div className="loader-bg">
            <div className="loader"></div>
            {message && <p className="loader-message">{message}</p>} {/* Display the message if it exists */}
        </div>
    );
}

export default Loader;