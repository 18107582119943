// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thankYouContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .checkmarkImage {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
  
.thankYouContainer h1,
.thankYouContainer h3 {
  margin: 0px; /* Adjust margin as needed */
}

.checkstatusLink{
  direction: rtl;
}`, "",{"version":3,"sources":["webpack://./src/styles/confirmation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;AAEF;;EAEE,WAAW,EAAE,4BAA4B;AAC3C;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".thankYouContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n  }\n  \n  .checkmarkImage {\n    width: 200px;\n    height: 200px;\n    margin-bottom: 20px;\n  }\n  \n.thankYouContainer h1,\n.thankYouContainer h3 {\n  margin: 0px; /* Adjust margin as needed */\n}\n\n.checkstatusLink{\n  direction: rtl;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
