import React from 'react';
import '../styles/quantityControls.css';

const QuantityControls = ({ quantity, maxQuantity, onIncrement, onDecrement }) => {
  return (
    <div className="product-quantity-controls">
      <button
        type="button"
        onClick={onDecrement}
        disabled={quantity <= 0}
      >
        -
      </button>
      <input
        type="number"
        className="quantity-input"
        value={quantity}
        readOnly
        max={maxQuantity}
        min={0}
      />
      <button
        type="button"
        onClick={onIncrement}
        disabled={quantity >= maxQuantity}
      >
        +
      </button>
    </div>
  );
};

export default QuantityControls;
