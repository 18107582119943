import React from 'react';
import { useErrorDialog } from '../global/errorContext';
//import React from 'react';
import '../styles/errorDialog.css';

const ErrorDialog = () => {
  const { isVisible, message, hideError } = useErrorDialog();

  if (!isVisible) return null;

  return (
    <>
      <div className="error-backdrop" onClick={hideError}></div>
      <div className="error-dialog">
        <h2>خطأ</h2>
        <p>{message}</p>
        <button className="ok-button" onClick={hideError}>موافق</button>
      </div>
    </>
  );
};

export default ErrorDialog;
