// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-quantity-controls {
    display: flex; /* Aligns the buttons and input horizontally */
  }
  
  .quantity-input {
    text-align: center;
    padding: 5px 8px; /* Adjust padding as needed */
    border: 1px solid #F3F4F6; /* Example border */
    border-radius: 2px;
  }
  
  /* Style your buttons as desired */
  .product-quantity-controls button {
    padding: 7px 15px; /* Adjust padding as needed */
    cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
    -webkit-user-select: none;
            user-select: none; /* Prevents the button text from being selected */
    border: 1px solid #F3F4F6; /* Example border */
    background-color: #f8f8f8; /* Example background color */
    line-height: 1; /* Aligns the plus and minus signs vertically */
    border-radius: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/styles/quantityControls.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,8CAA8C;EAC/D;;EAEA;IACE,kBAAkB;IAClB,gBAAgB,EAAE,6BAA6B;IAC/C,yBAAyB,EAAE,mBAAmB;IAC9C,kBAAkB;EACpB;;EAEA,kCAAkC;EAClC;IACE,iBAAiB,EAAE,6BAA6B;IAChD,eAAe,EAAE,kEAAkE;IACnF,yBAAiB;YAAjB,iBAAiB,EAAE,iDAAiD;IACpE,yBAAyB,EAAE,mBAAmB;IAC9C,yBAAyB,EAAE,6BAA6B;IACxD,cAAc,EAAE,+CAA+C;IAC/D,kBAAkB;EACpB","sourcesContent":[".product-quantity-controls {\n    display: flex; /* Aligns the buttons and input horizontally */\n  }\n  \n  .quantity-input {\n    text-align: center;\n    padding: 5px 8px; /* Adjust padding as needed */\n    border: 1px solid #F3F4F6; /* Example border */\n    border-radius: 2px;\n  }\n  \n  /* Style your buttons as desired */\n  .product-quantity-controls button {\n    padding: 7px 15px; /* Adjust padding as needed */\n    cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */\n    user-select: none; /* Prevents the button text from being selected */\n    border: 1px solid #F3F4F6; /* Example border */\n    background-color: #f8f8f8; /* Example background color */\n    line-height: 1; /* Aligns the plus and minus signs vertically */\n    border-radius: 2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
