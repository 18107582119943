// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input-container {
    display: flex;
    /* Aligns children (country code and input) in a row */
    align-items: center;
    /* Centers children vertically */
}

.country-code {
    background-color: #f2f2f2;
    /* Example background color */
    padding: 5px 10px;
    /* Add some padding */
    border: 1px solid #ccc;
    /* Add a border */
    margin: 5px;
    color: #333;
    /* Text color */
    font-weight: bold;
    /* Make the country code bold */
    font-size: 15px;
}

.phone-input {
    flex: 1 1;
    /* Allows the input to fill the available space */
    padding: 5px 10px;
    border: 1px solid #ccc;
    /* Add a border */
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
    height: 28px;

    /* Add the following lines to ensure consistent appearance across browsers */
    /* Remove default styling in Safari */
    /* Remove default styling in Firefox */
    appearance: none;
    /* Standard way to remove default styling */
}

.phone-input:focus {
    border-color: var(--primary-color);
    /* Change this to your desired focus border color */
    box-shadow: var(--secondary-color);
    /* Optional: Add a shadow to enhance the focus effect */
}`, "",{"version":3,"sources":["webpack://./src/styles/phoneField.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sDAAsD;IACtD,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;IACtB,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,iDAAiD;IACjD,iBAAiB;IACjB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,6BAA6B;IAC7B,YAAY;;IAEZ,4EAA4E;IAE5E,qCAAqC;IAErC,sCAAsC;IACtC,gBAAgB;IAChB,2CAA2C;AAC/C;;AAEA;IACI,kCAAkC;IAClC,mDAAmD;IACnD,kCAAkC;IAClC,uDAAuD;AAC3D","sourcesContent":[".phone-input-container {\n    display: flex;\n    /* Aligns children (country code and input) in a row */\n    align-items: center;\n    /* Centers children vertically */\n}\n\n.country-code {\n    background-color: #f2f2f2;\n    /* Example background color */\n    padding: 5px 10px;\n    /* Add some padding */\n    border: 1px solid #ccc;\n    /* Add a border */\n    margin: 5px;\n    color: #333;\n    /* Text color */\n    font-weight: bold;\n    /* Make the country code bold */\n    font-size: 15px;\n}\n\n.phone-input {\n    flex: 1;\n    /* Allows the input to fill the available space */\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    /* Add a border */\n    font-size: 16px;\n    outline: none;\n    transition: border-color 0.3s;\n    height: 28px;\n\n    /* Add the following lines to ensure consistent appearance across browsers */\n    -webkit-appearance: none;\n    /* Remove default styling in Safari */\n    -moz-appearance: none;\n    /* Remove default styling in Firefox */\n    appearance: none;\n    /* Standard way to remove default styling */\n}\n\n.phone-input:focus {\n    border-color: var(--primary-color);\n    /* Change this to your desired focus border color */\n    box-shadow: var(--secondary-color);\n    /* Optional: Add a shadow to enhance the focus effect */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
