// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    /* High z-index to ensure it sits on top of other content */
    width: 300px;
    /* Fixed width, or max-width: 90%; for responsiveness */
    padding: 20px;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.error-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent backdrop */
    z-index: 1000;
    /* Just below the dialog */
}

.ok-button {
    margin-top: 15px;
    background-color: var(--primary-color);
    border: 1px solid var(--secondary-colo);
    color: white;
    width: 100%;
    /* Make placeholder fully visible */
    height: 50px;
    border-radius: 5px;
    font-weight: bold;
    font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/styles/errorDialog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,2DAA2D;IAC3D,YAAY;IACZ,uDAAuD;IACvD,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,8BAA8B;IAC9B,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,sCAAsC;IACtC,uCAAuC;IACvC,YAAY;IACZ,WAAW;IACX,mCAAmC;IACnC,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".error-dialog {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1001;\n    /* High z-index to ensure it sits on top of other content */\n    width: 300px;\n    /* Fixed width, or max-width: 90%; for responsiveness */\n    padding: 20px;\n    background: white;\n    border: 1px solid #ccc;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.error-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Semi-transparent backdrop */\n    z-index: 1000;\n    /* Just below the dialog */\n}\n\n.ok-button {\n    margin-top: 15px;\n    background-color: var(--primary-color);\n    border: 1px solid var(--secondary-colo);\n    color: white;\n    width: 100%;\n    /* Make placeholder fully visible */\n    height: 50px;\n    border-radius: 5px;\n    font-weight: bold;\n    font-size: large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
