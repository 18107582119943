// OTPField.js
import '../styles/otpField.css';

import React, { useRef, useEffect } from 'react';

const OTPField = ({ index, otp, setOtp, length = 4 }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('paste', handlePaste);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    // Ensure the input is a number before setting it
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value.substring(value.length - 1, value.length); // Keep only the last digit
      setOtp(newOtp);

      // Move focus forward on input
      if (value && index < otp.length - 1) {
        inputRef.current.nextSibling.focus();
      }
    }
  };

  const handleBackspace = (e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRef.current.previousSibling.focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    if (pasteData.length === length && /^\d*$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
      inputRef.current.blur();
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      inputMode="numeric" // Helps ensure numeric keyboard on mobile
      maxLength="1"
      value={otp[index] || ''}
      onChange={handleChange}
      onKeyDown={handleBackspace}
      className="otp-field" // Add the class name here
      autoComplete="one-time-code"
    />
  );
};

export default OTPField;
