import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext'; // Import user data context
import QuantityControls from '../../components/quantityControls'; // Import quantity control component
import Loader from '../../components/loader'; // Import loader component
import apiService from '../../services/api.service'; // Import API service
import { useErrorDialog } from '../../global/errorContext'; // Import error dialog context
import '../../styles/orderDetails.css'; // Import CSS for order details
import '../../styles/warranty.css'; // Import CSS for warranty
import { checkWarrantyEligibility } from '../../utils/warranty'; // Import warranty utility function
// Component for displaying order details and handling warranty requests
const OrderDetailsWarrantyPage = () => {
  const { orderId } = useParams(); // Get order ID from URL params
  const { userData } = useContext(UserDataContext); // Get user data from context
  const navigate = useNavigate(); // Hook for navigation
  const { showError } = useErrorDialog(); // Hook for showing errors
  const [order, setOrder] = useState(null); // State to store order data
  const [warrantyItems, setWarrantyItems] = useState([]); // State to store warranty items
  const [email, setEmail] = useState(''); // State to store user email
  const [termsAccepted, setTermsAccepted] = useState(false); // State to track terms acceptance
  const [loadingMessage, setLoadingMessage] = useState(''); // State for loading message
  const [isLoading, setIsLoading] = useState(false); // State to manage loading indicator
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to manage submit button disabled status

  const options = {
    weekday: 'long', 
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    calendar: 'gregory',
    numberingSystem: 'latn'
};

  console.log('Initial States:', { warrantyItems, email, termsAccepted, isLoading, isSubmitDisabled }); // Log initial states

  // List of warranty reasons for user selection
  const warrantyReasons = [
    { value: 'عيب مصنعي', label: 'عيب مصنعي' },
    { value: 'دخول ماء', label: 'دخول ماء' },
    { value: 'عطل في المكينة', label: 'عطل في المكينة' },
    { value: 'تغيير لون', label: 'تغيير لون' },
    { value: 'وصل تالف', label: 'وصل تالف' },
    { value: 'غير ذلك', label: 'غير ذلك' },
  ];
  console.log('Warranty Reasons:', warrantyReasons); // Log warranty reasons

  /**
   * Handle checkbox change for selecting warranty items.
   * @param {number} index - Index of the item in the warrantyItems array.
   * @param {boolean} isSelected - Indicates whether the item is selected.
   */
  const handleSelectChange = (index, isSelected) => {
    console.log('Handle Select Change:', { index, isSelected }); // Log select change
    setWarrantyItems(currentItems =>
      currentItems.map((item, itemIndex) =>
        index === itemIndex ? { ...item, isSelected } : item
      )
    );
  };

  /**
   * Handle form submission for warranty request.
   * Validates form fields, uploads images, and sends warranty request.
   * @param {Object} e - The form event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if at least one product is selected
    const isAnyProductSelected = warrantyItems.some(item => item.isSelected);
    console.log('Is Any Product Selected:', isAnyProductSelected); // Log product selection status
    if (!isAnyProductSelected) {
      showError("يرجى اختيار منتج واحد على الأقل للضمان");
      return;
    }

    // Ensure all fields are filled for selected products
    const allFieldsFilled = warrantyItems.every(item =>
      !item.isSelected ||
      (item.quantity > 0 && item.warrantyReason && item.images.length > 0)
    );
    console.log('All Fields Filled:', allFieldsFilled); // Log field validation
    if (!allFieldsFilled) {
      showError("يرجى التأكد من أن جميع المنتجات المحددة لديها كمية أكبر من الصفر، وتم اختيار سبب للضمان، وتم رفع صورة واحدة على الأقل");
      return;
    }

    // Validate email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    console.log('Email:', email); // Log email
    if (!email.trim() || !emailRegex.test(email)) {
      showError('يجب إدخال بريد إلكتروني بصيغة صحيحة');
      return;
    }

    // Check if terms are accepted
    if (!termsAccepted) {
      showError("يجب قبول سياسة الضمان");
      return;
    }
    setLoadingMessage('...إنشاء طلب الضمان'); // Reset the loading message
    setIsLoading(true);


    try {
      // Upload images for selected warranty items
      for (let item of warrantyItems) {
        if (item.isSelected && item.images.length) {
          const formData = new FormData();
          item.images.forEach(image => formData.append('files', image));
          const response = await apiService.uploadWarrantyImages(formData);
          item.imageUrls = response.data.map(file => file.url);
          console.log('Uploaded Image URLs:', item.imageUrls); // Log uploaded image URLs
        }
      }

      // Prepare body for warranty request
      const body = {
        merchant: order.merchant,
        order_ref: orderId,
        mobile: userData.phoneNumber,
        email: email,
        products: warrantyItems.filter(item => item.isSelected),
        order_id: order._id,
        order_created_at: order.created_at,
        order_delivered_at: order.delivered_at?order.delivered_at:new Date(new Date(order.created_at).getTime() + 7 * 24 * 60 * 60 * 1000),
        order_discounts: order.discounts,
      };
      console.log('Warranty Request Body:', body); // Log warranty request body
      await apiService.createWarrantyRequest(body);
      const orderRef = order?.order_ref; // Use optional chaining here
      navigate('/thank-you-warranty', { state: { orderRef } });
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Fetch order details and calculate warranty eligibility for products.
     */
    const fetchOrder = async () => {
      setLoadingMessage('...التحقق من عناصر الضمان'); // Reset the loading message
      setIsLoading(true);

      try {
        // Fetch the order details
        const order = await apiService.getOrderById(orderId);
        console.log('Fetched Order:', order); // Log fetched order
        setOrder(order);



 
        // Determine the SKU list and warranty types
       // Mapping SKUs and Quantities
const skusAndQuantities = order.products.map(product => ({
  sku: product.sku,
  allowedQuantity: product.qty
}));

console.log('SKUs and Quantities:', skusAndQuantities); // Log SKUs and Quantities

let noWarrantySkus = [];
let exclusionSkus = [];
let checkAutomaticWatch = false;

if (process.env.REACT_APP_BRAND === "Zyros") {
  // Map no warranty SKUs
  noWarrantySkus = skusAndQuantities
    .filter(item => item.sku.toLowerCase().startsWith('zgv'))
    .map(item => ({ sku: item.sku, warrantyType: 'no-warranty' }));

  console.log('No Warranty SKUs:', noWarrantySkus); // Log no warranty SKUs

  // Map exclusion SKUs
  exclusionSkus = skusAndQuantities
    .filter(item => !item.sku.toLowerCase().startsWith('zgv'));

  console.log('Exclusion SKUs:', exclusionSkus); // Log exclusion SKUs

  // Set checkAutomaticWatch to true when specific condition is met
  checkAutomaticWatch = true;
} else {
  exclusionSkus = skusAndQuantities;
  console.log('Exclusion SKUs:', exclusionSkus); // Log exclusion SKUs
}

// Now you can send both SKUs and Quantities (as skusAndQuantities) and the checkAutomaticWatch flag

               // Get warranty data for the SKUs
               const warrantyCheckResponse = await apiService.warrantyCheck(order.merchant, order.order_ref, checkAutomaticWatch, exclusionSkus);
               console.log('Warranty Check Response:', warrantyCheckResponse); // Log warranty check response
       
               // Map the warranty data back to the products
               const updatedProducts = order.products.map(product => {
                 const noWarrantyMatch = noWarrantySkus.find(item => item.sku === product.sku);
                 const warrantyMatch = warrantyCheckResponse.find(item => item.sku === product.sku);
       
                 // Set allowedQuantity based on the warranty check response and available quantity
                 let allowedQuantity = product.qty
       
                 if (warrantyMatch) {
                   // Adjust allowedQuantity based on warranty checks (e.g., reduce based on existing warranties or returns)
                   allowedQuantity = warrantyMatch.allowedQuantity;
                 }
       
                 return {
                   ...product,
                   warrantyType: noWarrantyMatch ? noWarrantyMatch.warrantyType : warrantyMatch?.warrantyType,
                   hasWarranty: warrantyMatch?.hasWarranty || false,
                   hasReturn: warrantyMatch?.hasReturn || false,
                   allowedQuantity: allowedQuantity, // Include the allowed quantity based on the warranty check
                 };
               });
       console.log('order where qty? ', order)
       console.log('Updated Products:', updatedProducts); // Log updated products with allowed quantity
       
               // Restructure order in new var as needed by warranty eligibility check
               const checkOrder = {
                 merchant: order.merchant,
                 delivered_at: order.delivered_at?order.delivered_at:new Date(new Date(order.created_at).getTime() + 7 * 24 * 60 * 60 * 1000),
                 created_at: order.created_at,
                 discounts: order.discounts,
               };
               console.log('Check Order:', checkOrder); // Log check order
               console.log(' order shall  be with allowedQuantity: ',order)
               // Calculate warranty eligibility and update products
               const warrantyQualifiedProducts = await Promise.all(updatedProducts.map(async (product) => {
                 // Check warranty eligibility
                 const eligibility = await checkWarrantyEligibility(
                   product.hasReturn,
                   product.hasWarranty,
                   product.warrantyType,
                   product.allowedQuantity,
                   checkOrder
                 );
          console.log('Eligibility:', eligibility); // Log eligibility

          return {
            ...product,
            isQualified: eligibility.isQualified,
            allowedWarrantyDate: eligibility.allowedWarrantyDate,
            isBenefited: eligibility.isBenefited,
            requiresReview: eligibility.requiresReview,
            showHistory: eligibility.showHistory,
            quantity: 0,
            warrantyReason: '',
            images: [],
            isSelected: false
            
          };
        }));
        console.log('Warranty Qualified Products:', warrantyQualifiedProducts); // Log warranty qualified products

         // Count allowed products only
         // Calculate whether there are any allowed products
        const noAllowedProducts = warrantyQualifiedProducts.filter(item => item.isQualified.statusCode === 1).length === 0;

       
        setIsSubmitDisabled(noAllowedProducts)
        console.log(' noAllowedProducts: ',noAllowedProducts,'isSubmitDisabled: ',isSubmitDisabled)

        
        // Set the warranty items
         // Transform and set warranty items in one step
         setWarrantyItems(
          warrantyQualifiedProducts.map(({ img, ...product }) => ({
              ...product,
              thumbnail: img // Set img as thumbnail and exclude img from the rest of the product
          }))
      );
      } catch (error) {
         if (error.message.includes("Cannot read properties of null")) {
          showError('لم يتم العثور على تفاصيل الطلب. يرجى التحقق من رقم الطلب.');
          // Redirect if the order is not found or the delivered_at field is null
          navigate('/warranty/orders');
        } else {
          showError(error.message); // Generic error handling
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrder();
  }, [orderId, userData, showError, navigate]);


  

  useEffect(() => {
    /**
     * Handle changes in warrantyItems state to enable/disable submit button.
     */
    const anyProductSelected = warrantyItems.some(item => item.isSelected);
    const allFieldsValid = warrantyItems.every(item =>
      !item.isSelected ||
      (item.quantity > 0 && item.warrantyReason && item.images.length > 0)
    );
    console.log('Any Product Selected:', anyProductSelected); // Log any product selected
    console.log('All Fields Valid:', allFieldsValid); // Log all fields valid
  }, [warrantyItems, email, termsAccepted]);
 

  const handleDecrement = (index) => {
    setWarrantyItems(warrantyItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item // ensure quantity doesn't go below 0
    ));
  };

  const handleIncrement = (index, maxQuantity) => {
    setWarrantyItems(warrantyItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, quantity: Math.min(item.quantity + 1, maxQuantity) } : item // ensure quantity doesn't exceed max
    ));
  };

 

  const handleReasonChange = (index, reason) => {
      setWarrantyItems(warrantyItems.map((item, itemIndex) => {
        if (index === itemIndex) {
          if (reason === 'غير ذلك') {
            return { ...item, warrantyReason: reason };
          } else {
            return { ...item, warrantyReason: reason, customReason: '' };
          }
        } else {
          return item;
        }
      }));
    };
    // Function to handle custom reason input
  const handleCustomReasonChange = (index, customReason) => {
    setWarrantyItems(warrantyItems.map((item, itemIndex) =>
      index === itemIndex ? { ...item, customReason: customReason } : item
    ));
  };
  const handleImageUpload = (index, e) => {
    const uploadedImages = warrantyItems[index].images

    const newImages = Array.from(e.target.files).slice(0, 3);

    if (uploadedImages.length + newImages.length <= 3) {
      setWarrantyItems(currentItems =>
        currentItems.map((item, itemIndex) =>
          index === itemIndex ? { ...item, images: [...item.images, ...newImages] } : item
        )
      );
    } else {
      showError('يمكنك فقط رفع ما يصل إلى 3 صور لكل منتج');
    }
    e.target.value = null; // Reset the file input after the files have been selected
  };

  const handleDeleteImage = (index, imgIndex) => {
    const warrantyItem = warrantyItems[index];
    if (warrantyItem && warrantyItem.images && warrantyItem.images.length > imgIndex) {
      const updatedWarrantyItems = [...warrantyItems];
      updatedWarrantyItems[index].images.splice(imgIndex, 1);
      setWarrantyItems(updatedWarrantyItems);
    } else {
      console.error("Image not found or invalid index.");
    }
  };
  
//To disable the product based on its warranty eligibility status
  const checkDisabile = (statusCode,Q,S, showHistory) =>{
    if(showHistory)
      return false
    else if (Q && statusCode===1 && !S)
      return true
    else if(Q && statusCode!==1)
      return false
    // else if(!A && !Q)
    //   return true
    return false
  }

  return (
    
    <div className="request-form">
      
      {isLoading ? (
        <Loader message={loadingMessage}/> // Your loader here. Could be a spinner or custom loader component.
      ) : (
        <form onSubmit={handleSubmit} className="order-form">
           { 
           isSubmitDisabled &&
             <p className="warning-message">لا توجد منتجات يمكن طلب الضمان عليها في هذا الطلب</p>
            }
          {order?.products.map((product, index) => (
          <div key={product.id} className={`product-row ${checkDisabile(warrantyItems[index].isQualified.statusCode, warrantyItems[index].isQualified.isQualified, warrantyItems[index].isSelected,warrantyItems[index].showHistory)? 'disabled-overlay' : ''}`}>
          {/* Conditional Overlay */}
              {/*!warrantyItems[index].isSelected && <div className="overlay"></div>*/}
              {console.log(warrantyItems[index].showHistory,'h')}
              {warrantyItems[index].showHistory === 3 ? (
                  <p className="warning-message">
                    تم إنشاء طلب إرجاع بالفعل لهذا المنتج. يرجى الرجوع إلى الصفحة للتحقق من التحديثات{' '}
                    <Link to={`/returns/${order.order_ref}`}>السجل</Link>
                    <br />
                    تم إنشاء طلب ضمان بالفعل لهذا المنتج. يرجى الرجوع إلى الصفحة للتحقق من التحديثات{' '}
                    <Link to={`/warranties/${order.order_ref}/${encodeURIComponent(product.sku)}`}>السجل</Link>
                  </p>
                ):warrantyItems[index].showHistory === 2 ? (
                <p className="warning-message">
                  تم إنشاء طلب إرجاع بالفعل لهذا المنتج. يرجى الرجوع إلى الصفحة للتحقق من التحديثات{' '}
                  <Link to={`/returns/${order.order_ref}`}>السجل</Link>
                </p>
              ) : warrantyItems[index].showHistory === 1 ? (
                <p className="warning-message">
                  تم إنشاء طلب ضمان بالفعل لهذا المنتج. يرجى الرجوع إلى الصفحة للتحقق من التحديثات{' '}
                  <Link to={`/warranties/${order.order_ref}/${encodeURIComponent(product.sku)}`}>السجل</Link>
                </p>
              ) : warrantyItems[index].isQualified.statusCode === 4 ? (
                <p className="warning-message">
                  انتهت مدة الضمان لهذا المنتج{' '}
                </p>
              ):warrantyItems[index].isQualified.statusCode === 5 ? (
                <p className="warning-message">
                  الضمان لا يسري على هذا المنتج {' '}
                </p>
              ):null }
              <div className="product-item">

                <div className="product-details">
              {/* Conditional rendering for checkbox */}

              <p>{warrantyItems[index].isQualified.isQualified}</p>
             
                {warrantyItems[index].isQualified.isQualified &&(
                  <input
                    type="checkbox"
                    className="product-checkbox"
                    checked={warrantyItems[index].isSelected}
                    onChange={e => handleSelectChange(index, e.target.checked)}
                  />
                )}

                  <img src={product.img} alt={product.name} className="product-image" />
                  <div className="text-details">
                    <p className="product-title">{product.name}</p>
                    <p className="product-price">{product.price} رس</p>
                    {product?.options?.map((option, index) => (
                      <div>
                        <p className="product-options">{option.name}: {option.value.name}</p>
                      </div>
                    ))}
                    {warrantyItems[index].warrantyType === 'no-warranty' ? (
                      <p className="product-price">الضمان لا يسري على هذا المنتج</p>
                    ) : (
                      <>
                      {
                            (warrantyItems[index].isQualified.statusCode !== 2 && warrantyItems[index].isQualified.statusCode !== 3) && (
                        <p className="product-price">
                          تاريخ انتهاء الضمان: {warrantyItems[index].isBenefited && !warrantyItems[index].requiresReview
                            ? (warrantyItems[index].allowedWarrantyDate ? new Date(warrantyItems[index].allowedWarrantyDate).toLocaleDateString('ar-EG', options) : 'غير محدد')
                            : (warrantyItems[index].allowedWarrantyDate ? warrantyItems[index].allowedWarrantyDate.toLocaleDateString() : 'غير محدد')}
                        </p>
                        )
                      }   
                        {
                            (warrantyItems[index].isQualified.statusCode !== 2 && warrantyItems[index].isQualified.statusCode !== 3) && (
                              <p className="product-price">
                                الضمان ساري: {warrantyItems[index].requiresReview
                                  ? 'قيد المراجعة من فريق خدمة العملاء'
                                  : (warrantyItems[index].isQualified.statusCode === 1 ? 'نعم' : 'لا')}
                              </p>
                            )
                        }
                        {
                            (warrantyItems[index].isQualified.statusCode !== 2 && warrantyItems[index].isQualified.statusCode !== 3) && (                       
                        <p className="product-price">
                          {warrantyItems[index].isQualified.statusCode===4 &&
                             
                            'انتهت مدة الضمان لهذا المنتج'
                              }
                           {warrantyItems[index].isQualified.statusCode===1 &&
                              'لم يتم إنشاء طلب ضمان لهذا المنتج بعد. '
                             }
                            </p>
                          )
                        }
                      </>
                    )}
                    
                  </div>
                </div>
                {warrantyItems[index].isQualified.statusCode===1 ? (
                <div className="quantity-details">
                  <p>أقصى كمية: {product.qty}</p>
                  {console.log(product.qty, '|!!',warrantyItems[index].allowedQuantity)}
                  {/* only when the products quantities in the order is not equal the allowed quantity show this 
                  for a product  that has quantity > 1 & has benefited from warranty for some quantities of that product in the order*/}
                  { product.qty !== warrantyItems[index].allowedQuantity &&
                  (<p>أقصى كمية يمكن طلب الضمان عليها: {warrantyItems[index].allowedQuantity}</p>
                  )}
                  <QuantityControls
                    quantity={warrantyItems[index].quantity}
                    maxQuantity={warrantyItems[index].allowedQuantity}
                    onIncrement={() => handleIncrement(index, warrantyItems[index].allowedQuantity)}
                    onDecrement={() => handleDecrement(index)}
                  />
                </div>):(<></>)}

              </div>
              {warrantyItems[index].isQualified.statusCode===1 ? (
              <div className='selections'>
                <p className="required-field">سبب الضمان</p>
                <select
                  className="return-reason"
                  value={warrantyItems[index].warrantyReason}
                  onChange={(e) => handleReasonChange(index, e.target.value)}
                >
                  <option value="" className="required-field">يرجى ذكر سبب ضمان المنتج</option>
                  {warrantyReasons.map((reason, reasonIndex) => (
                    <option key={reasonIndex} value={reason.value}>{reason.label}</option>
                  ))}
                </select>
                {warrantyItems[index].warrantyReason === 'غير ذلك' && (
                <input
                  type="text"
                  value={warrantyItems[index].customReason}
                  onChange={(e) => handleCustomReasonChange(index, e.target.value)}
                  placeholder="الرجاء إدخال السبب"
                />
              )}
                <div className="browse-img">
                  <p className="required-field">صور المنتج  على الطبيعة</p>
                  <input
                    type="file"
                    multiple
                    id={`image-upload-${index}`}
                    style={{ display: 'none' }} // Hide the actual file input
                    onChange={(e) => handleImageUpload(index, e)}
                    accept="image/*" // Only allow image files to be selected
                  />
                  <button
                    type="button" // Ensure it's a button with type="button" to prevent form submission
                    onClick={
                      () => {
                        document.getElementById(`image-upload-${index}`).click()
                      }}
                  >
                    استعراض مكتبة الصور
                  </button>

                  {/* Preview Uploaded images and the ability to delete them */}
                  <div className="image-preview-container">
                    {warrantyItems[index].images.map((imageFile, imgIndex) => (
                      <div key={imgIndex} className="image-preview">
                        <img src={URL.createObjectURL(imageFile)} alt={`Preview ${imgIndex}`} />
                        <button
                          type="button"
                          className="delete-image-btn"
                          onClick={() => handleDeleteImage(index, imgIndex)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>

                </div>
              </div>):(<></>)}
            </div>
          ))}

          {!isSubmitDisabled &&
          <div className="email-container">
            <h3>البريد الالكتروني:</h3>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="البريد الالكتروني"
            />
            <p> لاستقبال اشعارات حالة طلب الضمان</p>
          </div>
}

          {!isSubmitDisabled &&
          <div className="terms-container">
            <input
              type="checkbox"
              className="terms-checkbox"
              checked={termsAccepted}
              onChange={e => setTermsAccepted(e.target.checked)}
            />
            {/* TODO: warranty terms & check */}
             <p> اوافق على سياسة الضمان للمتجر</p>
             <Link to={`/warranty-terms`}> نرجو الاطلاع على سياسة الضمان للمتجر </Link>
            </div>
}
        {!isSubmitDisabled &&
            <button className='submit-return-request'
              type='submit'
           

            >
              إرسال الطلب
            </button>
      }
        </form >
      )}
    </div >
  );
  
  
  
  
};

export default OrderDetailsWarrantyPage;
