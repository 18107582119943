// Import necessary modules and services
import apiService from '../services/api.service';
import { isDiscounted } from '../utils/isDiscounted';
import { handleDiscountCodeFormat } from '../utils/discountCodeUtils';
import extractOrderId from '../utils/extractOrderId';
import brands from '../brands.json';

/**
 * Converts a date string or object to a JavaScript Date object.
 * @param {string|Date} date - The date to convert.
 * @returns {Date} The converted Date object.
 */
const toDate = (date) => {
    return new Date(date);
};

/**
 * 
 * Determines if a product is warranty qualified based on return status, existing warranty, and warranty type.
 * 
 *  * Status Code Meanings:
 *  * - Not Qualified: The product that has already returned or benifited from warranty times = the product quantity.
 * 1 - Qualified: The product is eligible for a warranty.
 * 4 - Not Allowed (Expired): The warranty period has expired.
 * 5 - No Warranty: The product belongs to the "no-warranty" type and is not eligible for a warranty.
 * 0 - Error: An error occurred during the warranty qualification check.
 * 
 *  * These codes are a prevention mechanism in the frontend, ensuring only eligible products trigger API requests.
 * This reduces server load and prevents employees from handling invalid requests, improving efficiency.
 * 
 * @param {boolean} hasReturn - Indicates if the product has been returned.
 * @param {boolean} hasWarranty - Indicates if the product already has a warranty.
 * @param {string} warrantyType - The type of warranty.
 * @returns {Promise<Object>} An object containing the qualification status and a status code.
 */
export const isWarrantyQualified = async (hasReturn, hasWarranty, warrantyType, allowedQuantity) => {
    try {
        let showHistory;

        // Determine history visibility
        if (hasWarranty && hasReturn) {
            showHistory = 3; // Both histories
        } else if (hasWarranty) {
            showHistory = 1; // Warranty history
        } else if (hasReturn) {
            showHistory = 2; // Return history
        }
         // Check if allowedQuantity is 0 before proceeding
         if (allowedQuantity === 0) {
            console.log('Product has no available quantity for warranty.');
            return { isQualified: false, showHistory };
         }
        console.log('Checking warranty qualification:', { hasReturn, hasWarranty, warrantyType });

        // If the product has both warranty and return, assign a special status code (statusCode = 7)
        if (hasReturn && hasWarranty) {
            console.log('Product has both return and warranty, special case for showing history.');
            return { isQualified:true, showHistory }; // Special case for both warranty and return
        }

        else if (hasReturn) {
            console.log('Returned product, not qualified partially for a new warranty.');
            return { isQualified: true, showHistory };
        } else if (hasWarranty) {
            console.log('Product with existing warranty, not qualified partially.');
            return { isQualified: true, showHistory };
        } else if (warrantyType !== "no-warranty") {
            console.log('Product qualified for warranty.');
            return { isQualified: true, statusCode: 1 };
        } else {
            console.log('Product is of "no-warranty" type, not allowed.');
            return { isQualified: false, statusCode: 5 };
        }
    } catch (error) {
        console.error('Error in isWarrantyQualified:', error);
        return { isQualified: false, statusCode: 0 }; // Return error status if an exception occurs
    }
};

/**
 * Calculates warranty details based on warranty type, order details, and discount code.
 * @param {string} warrantyType - The type of warranty.
 * @param {Object} order - The order details.
 * @returns {Promise<Object>} An object containing warranty-related details such as allowed warranty date, eligibility, benefit status, and review requirement.
 */
export const calculateWarrantyDetails = async (warrantyType, allowedWarrantyPeriod, expired, order) => {
    console.log('Calculating warranty details:', { warrantyType, order });

    console.log('Allowed warranty period:', allowedWarrantyPeriod);

    // Convert order dates to Date objects
    const createdAtDate = toDate(order.created_at);
    const deliveredAtDate = order.delivered_at ? toDate(order.delivered_at) : (order.created_at ? new Date(new Date(order.created_at).getTime() + 7 * 24 * 60 * 60 * 1000) : null);

    if (allowedWarrantyPeriod === 0 ) {
        // No warranty, return default values
        console.log('No warranty, returning default values.');
        return { allowedWarrantyDate: null, isAllowed: false, isBenefited: false, requiresReview: false };
    } else if (allowedWarrantyPeriod === 1) {
        // Special case for 24-hour warranty from delivery date
        if (deliveredAtDate) {
            const allowedWarrantyDate = new Date(deliveredAtDate.getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
            console.log('Special case for 24-hour warranty. Allowed warranty date:', allowedWarrantyDate);
            return { allowedWarrantyDate, isAllowed: new Date() <= allowedWarrantyDate, isBenefited: false, requiresReview: false };
        } else {
            // Handle case where deliveredAtDate is null
            return { allowedWarrantyDate: null, isAllowed: false, isBenefited: false, requiresReview: false };
        }
    } else {
        // Calculate warranty details for products with a valid warranty period
        let allowedWarrantyDate, isBenefited = false, requiresReview = false;

        if(!expired){
            if (isDiscounted(order.discounts)) {
                console.log('Order is discounted. Fetching benefactor order details.');
                const { allowedWarrantyDate: benefactorOrderDate, isBenefited: benefactorIsBenefited, requiresReview: benefactorRequiresReview } = await getBenefactorOrderDate(order, allowedWarrantyPeriod);
                allowedWarrantyDate = benefactorOrderDate || new Date(createdAtDate.getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
                isBenefited = benefactorIsBenefited;
                requiresReview = benefactorRequiresReview;
            } else {
                allowedWarrantyDate = new Date(createdAtDate.getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
            }
        }
        //return expiry date for expired warranties
        else{
            allowedWarrantyDate = new Date(createdAtDate.getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
            console.log(' ... ',allowedWarrantyDate)
        }

        console.log('Calculated warranty details: ...', { allowedWarrantyDate, isAllowed: new Date() <= allowedWarrantyDate, isBenefited, requiresReview });
        return {
            allowedWarrantyDate,
            isAllowed: !expired?new Date() <= allowedWarrantyDate:false,
            isBenefited,
            requiresReview
        };
    }
};



/**
 * Retrieves the benefactor order date and other related details.
 * @param {Object} order - The order details.
 * @param {number} allowedWarrantyPeriod - The allowed warranty period in days.
 * @returns {Promise<Object>} An object containing the benefactor order date, benefit status, and review requirement.
 */
export const getBenefactorOrderDate = async (order, allowedWarrantyPeriod) => {
    console.log('Fetching benefactor order date:', { order, allowedWarrantyPeriod });

    const brandPrefix = process.env.REACT_APP_BRAND.slice(0, 2); // Get brand prefix
    const discountCode = order.discounts[0]?.code; // Extract discount code
    const discountType = handleDiscountCodeFormat(discountCode, brandPrefix);

    console.log('Discount code:', discountCode);
    console.log('Discount type:', discountType);

    let isBenefited = false;
    let requiresReview = false;
    let allowedWarrantyDate;

    try {
        // handle benefactored order that has been done through the current system
        if (discountType === "current") {
            console.log('Handling "current" discount type.');
            isBenefited = true;
            const benefactorOrderRef = extractOrderId(discountCode);// extract the order id from the discount code
            let benefactorOrder = await apiService.getOrderById(benefactorOrderRef);// fetch the benefacor order details

            console.log('Benefactor order:', benefactorOrder);

            // when the benefactor order is not found in the system, start fetching from Salla
            if (!benefactorOrder) {
                // If order not found, fetch from Salla
                const benefactorOrders = await apiService.fetchSallaOrderById(order.merchant, benefactorOrderRef);

                console.log('Salla benefactor orders:', benefactorOrders);

                if (benefactorOrders && benefactorOrders.length > 0) {
                    const firstOrder = benefactorOrders[0];
                    allowedWarrantyDate = new Date(new Date(firstOrder.date.date).getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
                } else {
                    throw new Error('No benefactor orders found in Salla response');
                }
            } else {
                allowedWarrantyDate = new Date(new Date(benefactorOrder.created_at).getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
            }
        // handle benefactored order that has been done through the legacy system
        // forward the handling to the employee by flag the order as require review
        } else if (discountType === "legacy") {
            console.log('Handling "legacy" discount type.');
            isBenefited = true;
            requiresReview = true;
        }
        
        // Default to current order date if no benefactor order date is found
        if (!allowedWarrantyDate) {
            allowedWarrantyDate = new Date(toDate(order.created_at).getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
        }
    } catch (error) {
        console.error('Error fetching benefactor order:', error);
        allowedWarrantyDate = new Date(new Date().getTime() + allowedWarrantyPeriod * 24 * 60 * 60 * 1000);
    }

    console.log('Benefactor order date result:', { allowedWarrantyDate, isBenefited, requiresReview });
    return {
        allowedWarrantyDate,
        isBenefited,
        requiresReview,
    };
};

/**
 * Checks if a product's warranty eligibility is valid and then determines if it's allowed.
 * @param {boolean} hasReturn - Indicates if the product has been returned.
 * @param {boolean} hasWarranty - Indicates if the product already has a warranty.
 * @param {string} warrantyType - The type of warranty.
 * @param {Object} order - The order details.
 * @returns {Promise<Object>} An object containing the eligibility status, allowed date, benefit status, and review requirement.
 */
export const checkWarrantyEligibility = async (hasReturn, hasWarranty, warrantyType,allowedQuantity, allowedWarrantyPeriod, expired, order) => {
    console.log('Checking warranty eligibility:', { hasReturn, hasWarranty, warrantyType, order });


    try {
        
        // Check warranty qualification first
        const qualificationResult = await isWarrantyQualified(hasReturn, hasWarranty, warrantyType, allowedQuantity);
        console.log('Warranty qualification result:', qualificationResult);

        // If warranty is not qualified, return the result with default values for isBenefited and requiresReview
        if (!qualificationResult.isQualified) {
            console.log('qualification failed')
            return {
                isQualified: {...qualificationResult}, // Includes isQualified and statusCode
                isBenefited: false, // Default value since qualification failed
                requiresReview: false, // Default value since qualification failed
                showHistory:qualificationResult.showHistory

            };
        }

        // If warranty is qualified, calculate warranty details
        const { allowedWarrantyDate, isAllowed, isBenefited, requiresReview } = await calculateWarrantyDetails(warrantyType, allowedWarrantyPeriod, expired, order);
        console.log('Calculated warranty details:', { allowedWarrantyDate, isAllowed, isBenefited, requiresReview });

        // Return the final result based on allowance
        return {
              
            isQualified: {
                isQualified: isAllowed, // Qualification depends on allowance
                statusCode: isAllowed ? 1 : (!allowedWarrantyDate? 5 : 4), // 1 if allowed, 4 if not & 5 if pass with null date (no-warranty) //TODO: improve separation of concerns
            },
            allowedWarrantyDate,
            isBenefited, // Set by calculateWarrantyDetails
            requiresReview, // Set by calculateWarrantyDetails
            showHistory:qualificationResult.showHistory
        };
    } catch (error) {
        console.error('Error in checkWarrantyEligibility:', error);
        return {
            isQualified: {
                isQualified: false,
                statusCode: 0, // Error occurred
            },
            isBenefited: false, // Default value on error
            requiresReview: false // Default value on error
        };
    }
};
