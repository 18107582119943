// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    /* High z-index to ensure it sits on top of other content */
    width: 300px;
    /* Fixed width, or max-width: 90%; for responsiveness */
    padding: 20px;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.warning-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent backdrop */
    z-index: 1000;
    /* Just below the dialog */
}

.dialog-buttons {
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    margin-top: 15px;
    gap: 10px;
  }
  
  .dialog-buttons button {
    flex: 1 1;
    height: 50px;
    border-radius: 5px;
    font-weight: bold;
    font-size: large;
    border: none;
    cursor: pointer;
  }
  .cancel-button {
    background-color: #e0e0e0;
    color: #333;
  }
  .ok-button {
    background-color: var(--primary-color);
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/warningDialog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,2DAA2D;IAC3D,YAAY;IACZ,uDAAuD;IACvD,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,8BAA8B;IAC9B,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,SAAO;IACP,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB;EACA;IACE,yBAAyB;IACzB,WAAW;EACb;EACA;IACE,sCAAsC;IACtC,YAAY;EACd","sourcesContent":[".warning-dialog {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1001;\n    /* High z-index to ensure it sits on top of other content */\n    width: 300px;\n    /* Fixed width, or max-width: 90%; for responsiveness */\n    padding: 20px;\n    background: white;\n    border: 1px solid #ccc;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.warning-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Semi-transparent backdrop */\n    z-index: 1000;\n    /* Just below the dialog */\n}\n\n.dialog-buttons {\n    display: flex;\n    justify-content: space-between;\n    align-items:baseline;\n    margin-top: 15px;\n    gap: 10px;\n  }\n  \n  .dialog-buttons button {\n    flex: 1;\n    height: 50px;\n    border-radius: 5px;\n    font-weight: bold;\n    font-size: large;\n    border: none;\n    cursor: pointer;\n  }\n  .cancel-button {\n    background-color: #e0e0e0;\n    color: #333;\n  }\n  .ok-button {\n    background-color: var(--primary-color);\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
