// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /* BankDetailsForm.css */

/* Apply RTL direction to the entire form */
.bank-details-form {
    padding: 20px;
   direction: rtl;
   text-align: right;
   background-color: white;
 }
 
 /* Style paragraphs containing the input fields */
 
 /* Add asterisk to required fields */
.required-field:after {
   content: '*';
   color: red; /* Required mark color */
   position: absolute;
   margin-right: 5px;
 }
 
 /* Style input fields */
 .bank-details-form input[type="text"] {
   width: 90%; /* Full width */
   padding: 10px;
   border: 1px solid #F3F4F6; /* Example border */
   border-radius: 5px; /* Rounded corners */
 }
 
 /* Adjust placeholder text style if needed */
 .bank-details-form input[type="text"]::placeholder {
   color: #888;
   opacity: 1; /* Make placeholder fully visible */
 }`, "",{"version":3,"sources":["webpack://./src/styles/bankForm.css"],"names":[],"mappings":"CAAC,wBAAwB;;AAEzB,2CAA2C;AAC3C;IACI,aAAa;GACd,cAAc;GACd,iBAAiB;GACjB,uBAAuB;CACzB;;CAEA,iDAAiD;;CAEjD,oCAAoC;AACrC;GACG,YAAY;GACZ,UAAU,EAAE,wBAAwB;GACpC,kBAAkB;GAClB,iBAAiB;CACnB;;CAEA,uBAAuB;CACvB;GACE,UAAU,EAAE,eAAe;GAC3B,aAAa;GACb,yBAAyB,EAAE,mBAAmB;GAC9C,kBAAkB,EAAE,oBAAoB;CAC1C;;CAEA,4CAA4C;CAC5C;GACE,WAAW;GACX,UAAU,EAAE,mCAAmC;CACjD","sourcesContent":[" /* BankDetailsForm.css */\n\n/* Apply RTL direction to the entire form */\n.bank-details-form {\n    padding: 20px;\n   direction: rtl;\n   text-align: right;\n   background-color: white;\n }\n \n /* Style paragraphs containing the input fields */\n \n /* Add asterisk to required fields */\n.required-field:after {\n   content: '*';\n   color: red; /* Required mark color */\n   position: absolute;\n   margin-right: 5px;\n }\n \n /* Style input fields */\n .bank-details-form input[type=\"text\"] {\n   width: 90%; /* Full width */\n   padding: 10px;\n   border: 1px solid #F3F4F6; /* Example border */\n   border-radius: 5px; /* Rounded corners */\n }\n \n /* Adjust placeholder text style if needed */\n .bank-details-form input[type=\"text\"]::placeholder {\n   color: #888;\n   opacity: 1; /* Make placeholder fully visible */\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
