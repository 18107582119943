import React, { useState, useEffect } from 'react';
import UserDataContext from './UserDataContext';

const UserDataProvider = ({ children }) => {
  // Check sessionStorage first, else set default state
  const [userData, setUserData] = useState(() => {
    const savedUserData = sessionStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : { phoneNumber: '' };
  });

  // Use useEffect to update sessionStorage when userData changes
  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
