import { useParams, useNavigate } from 'react-router-dom';
import UserDataContext from '../../session/UserDataContext'; // Import the context
import React, { useEffect, useState, useContext } from 'react';
import apiService from '../../services/api.service';
import { useErrorDialog } from '../../global/errorContext';
import StatusHistory from '../../components/statusHistory'
import Loader from '../../components/loader';
import Big from 'big.js';
import '../../styles/returns.css'; // Import the CSS module

const ReturnsPage = () => {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { showError } = useErrorDialog();
    const { userData } = useContext(UserDataContext); // Access userData from context
    const [returns, setReturns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // const [totalPrice, setTotalPrice] = useState([]);

    useEffect(() => {
        const fetchReturns = async () => {
            setIsLoading(true); // Start loading
            try {
                const returns = await apiService.getReturns(userData.phoneNumber, orderId);
                setReturns(returns);
                // Calculate the total price using Big.js
            } catch (error) {
                showError(error.toString());
            }finally {
                setIsLoading(false); // Start loading
            }
        };
        if (userData.phoneNumber) {
            fetchReturns();
        } else {
            // Handle case where mobile is not provided, e.g., redirect
            navigate('/'); // Adjust as needed
        }
    }, [userData.phoneNumber, orderId]);


    return (
        <div className='returns-page'>
             {isLoading ? (
                <Loader /> // Your loader here. Could be a spinner or custom loader component.
            ) : (
            <>
            <div className='banner'>
                {/*<img src={zyros_logo} className='logo' />*/}
            </div>
            <h1 className='title'>حالة الإرجاع</h1>
            {/* User info should probably come from userData, hardcoded for example */}
            <div className='userInfo'>
                {/*  <img src="/path-to-user-avatar.jpg" alt="User Avatar" className={styles.avatar} />*/}
            </div>
            <div className="returns-list">
                {returns.length > 0 ? (
                    returns.map((returnRequest, index) => (
                        <ReturnCard key={index} returnRequest={returnRequest} />
                    ))
                ) : (
                    <p> {orderId} لايوجد سجل مسترجعات للطلب رقم</p>  // "No return requests" in Arabic
                )}
            </div>
            </>
            )}
        </div>
    );
}


const ReturnCard = ({ returnRequest }) => {
    const options = {
        weekday: 'long', // long representation for the day
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        calendar: 'gregory', // ensure use of the Gregorian calendar
        numberingSystem: 'latn' // use Latin numerals instead of Arabic numerals
    };
    // Calculate the total price using Big.js
    const totalPrice = returnRequest.products.reduce((acc, product) => {
        // Ensure the product quantity and price are properly handled
        const pricePerItem = new Big(product.price);
        const quantity = product.quantity;
        const totalForProduct = pricePerItem.times(quantity); // Multiply price by quantity
        return acc.plus(totalForProduct); // Add the total for this product to the accumulator

    }, new Big(0));

    return (
        <div className="return-card">
            <p className="return-date">{new Date(returnRequest.created_at).toLocaleDateString('ar-EG', options)}</p>
            <div className="products-thumbnails">
                {returnRequest.products.map((product, index) => (
                    <img key={index} src={product.thumbnail} alt={`Product ${index + 1}`} />
                ))}
            </div>
            <p className="total-price">Total: {totalPrice.toString()} SAR</p>
            <StatusHistory currentSlug={returnRequest.status.slug} />
        </div>
    );
};

export default ReturnsPage;