//import React from 'react';
import '../styles/navBar.css';
import brands from '../brands.json';

const NavBar = () => {
    const logoPath = `/images/${brands[process.env.REACT_APP_BRAND].logo}`;

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logoPath} alt="Logo" />
            </div>
            <div className="navbar-links">
                {/* Add your navigation links here */}
            </div>
        </nav>
    );
}

export default NavBar;
