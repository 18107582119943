
import apiService from '../../services/api.service'; // Import API service
import EditImagesRequestPage from '../editImage'


// export default EditImagesWarrantyPage;
const EditImagesReturnPage = () => {
return(
  <EditImagesRequestPage
  getRequest={apiService.getReturnRequest}
  updateRequest={apiService.updateReturnImagesAndStatus}
  uploadImages={apiService.uploadReturnImages}
  deleteImages={apiService.deleteReturnImages}
  requestType="return"
/>
)

}
export default EditImagesReturnPage