import '../styles/phoneField.css';

const PhoneField = ({ value, onChange }) => {
    const handleChange = (e) => {
        // Ensure only numbers and the plus sign (+) are allowed in the input
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        onChange(newValue);
    };

    return (
        <div className="phone-input-container">
        <span className="country-code">
        {/*<img src="path_to_your_flag_image/saudi_arabia_flag.svg" alt="Saudi Flag" className="flag-icon" />*/}
            +966
            </span>
        <input
            type="tel"
            className="phone-input"
            placeholder="51 234 5678"
            value={value}
            onChange={handleChange}
        />
    </div>
    
    );
}

export default PhoneField;
