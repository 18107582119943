// src/utils/payment_enumeration.js
// Import your icons here. Example:
// import bankIcon from '../path/to/bankIcon.svg';

const paymentMethods = {
    cod: {
      name: "كاش عند الاستلام",
      icon: "/images/cod-icon.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا",
    },
    bank: {
      name: "تحويل بنكي",
      icon: "/images/bank-transfer-icon.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر الاسترداد أو التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    mada: {
      name: "مدى",
      icon: "/images/mada-logo.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر الاسترداد أو التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    credit: {
      name: "بطاقة ائتمانية",
      icon: "/images/credit-logo.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر الاسترداد أو التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    credit_card: {
      name: "بطاقة ائتمانية",
      icon: "/images/credit-logo.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر الاسترداد أو التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    tabby_installment: {
      name: "تقسيط بواسطة تابي",
      icon: "/images/tabby-logo.png", // Replace with your actual icon path or import
      policy:"اعادة المبلغ تتم عبر نظام تابي , وتابي تعيد جدولة و تسوية الدفعات الخاصة بك خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    tamara_installment: {
      name: "تقسيط بواسطة تمارا",
      icon: "/images/tamara-logo.png", // Replace with your actual icon path or import
      policy: "اعادة المبلغ تتم عبر نظام تمارا , وتمارا تعيد جدولة و تسوية الدفعات الخاصة بك خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
    },
    stc_pay: {
        name: "اس تي سي باي",
        icon: "/images/stcpay-logo.png", // Replace with your actual icon path or import
        policy: "اعادة المبلغ تتم عبر الاسترداد أو التحويل البنكي خلال 1-14 يوم عمل من تاريخ وصول المنتجات الى مستودعاتنا"
      },
      free: {
        name: "مجاني",
        icon: "/images/free-icon.png", // Replace with your actual icon path or import
        policy: ""
      },
      mispay_installment: {
        name: "ام اي اس باي",
        icon: "/images/mispay-logo.png", // Replace with the actual icon path
        policy: "اعادة المبلغ تتم عبر نظام ام اي اس باي، ويتم إعادة جدولة وتسوية الدفعات الخاصة بك خلال 1-14 يوم عمل من تاريخ وصول المنتجات إلى مستودعاتنا"
    },
    
    madfu_installment: {
        name: "مدفوع",
        icon: "/images/madfu-logo.png", // Replace with the actual icon path
        policy: "اعادة المبلغ تتم عبر نظام مدفوع، ويتم إعادة جدولة وتسوية الدفعات الخاصة بك خلال 1-14 يوم عمل من تاريخ وصول المنتجات إلى مستودعاتنا"
    },
    
    emkan_installment: {
        name: "باي لييتر امكان",
        icon: "/images/emkan-logo.png", // Replace with the actual icon path
        policy: "اعادة المبلغ تتم عبر نظام باي لييتر امكان، ويتم إعادة جدولة وتسوية الدفعات الخاصة بك خلال 1-14 يوم عمل من تاريخ وصول المنتجات إلى مستودعاتنا"
    }
  };
  
  // Function to get the payment method details by slug
  function getPaymentMethodDetails(slug) {
    // Return the corresponding entry or undefined if not found
    return paymentMethods[slug] || { name: "غير معروف", icon: "/images/unknown-payment-icon.png", policy: "" };
  }
  
  export default getPaymentMethodDetails;
  