// logger.js

// Save the original console.log method
const originalLog = console.log;

// Override the console.log method
console.log = function(...args) {
  // Check the environment
  if (process.env.NODE_ENV !== 'production') {
    // Call the original console.log with the arguments
    originalLog.apply(console, args);
  }
};

// You can similarly override other console methods if needed
const originalWarn = console.warn;
console.warn = function(...args) {
  if (process.env.NODE_ENV !== 'production') {
    originalWarn.apply(console, args);
  }
};

const originalError = console.error;
console.error = function(...args) {
  if (process.env.NODE_ENV !== 'production') {
    originalError.apply(console, args);
  }
};
