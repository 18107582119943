// utils/discountUtils.js

/**
 * Handles the validation and processing of discount codes based on different formats..
 * @param {string} code - The discount code to handle.
 * @param {string} prefix - The prefix parameter to match against.
 */
export function handleDiscountCodeFormat(code, prefix) {
    // Define regex patterns for current and legacy system formats based on the prefix
    const currentSystemRegex = new RegExp(`^${prefix.toLocaleLowerCase()}\\d{9}\\w{2}$`);
    const legacySystemRegex = new RegExp(`^${prefix.toLocaleLowerCase()}\\w+$`);

    // Test the discount code against the regex patterns
    if (currentSystemRegex.test(code.toLocaleLowerCase())) {
        // Handle current system logic
        return "current";
    } else if (legacySystemRegex.test(code.toLocaleLowerCase())) {
        // Handle legacy system logic
        return "legacy";
    } else {
        // Handle default case or raise an error if needed
        return "marketing";
    }
}
