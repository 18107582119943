import { useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '../../styles/confirmation.css';

const ThankYouWarrantyPage = () => {
  const location = useLocation();
  const orderRef = location.state?.orderRef; // Get the order reference from the state

  return (
    <div className="thankYouContainer">
      <img src="/images/checkmark-circle.svg" className="checkmarkImage" alt="Checkmark" />
      <h1>تم إرسال طلب الضمان بنجاح</h1>
      <h3>
        تم ارسال طلب الضمان بنجاح وسيتم إرسال تحديثات طلب الضمان عبر الإيميل المسجل في طلب الضمان
      </h3>
      <h4>
      <p className='checkstatusLink'>
      يمكنك التحقق من حالة طلبك هنا <Link to={`/warranties/${orderRef}`} className="statusLink">السجل</Link>.
      </p>
      </h4>
    </div>
  );
};

export default ThankYouWarrantyPage;
