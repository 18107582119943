// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: auto;
}


.otp-form {
    width: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.otp-form button:disabled {
    background-color: #cccccc; /* Light grey background */
    color: #666666; /* Darker grey text */
    border: 1px solid #999999; /* Grey border */
    cursor: not-allowed; /* Shows a 'not-allowed' cursor on hover */
  }

.otp-fields {
    display: flex;
    gap: 15px;
    height: 30px;
    justify-content: center;
    margin-top: 50px;
}

.no-otp-msg {
    font-size: small;
}

/* Assuming your button is inside a form with the class "login-form" */
.otp-form button {
    width: 100%; /* Set the button width to 100% of its parent container */
    background-color: var(--primary-color);
    border: 1px solid var(--secondary-color);
    color: white;
    height: 40px;
    margin-top: 20px;
    font-size: medium;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/styles/otp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,cAAc,EAAE,qBAAqB;IACrC,yBAAyB,EAAE,gBAAgB;IAC3C,mBAAmB,EAAE,0CAA0C;EACjE;;AAEF;IACI,aAAa;IACb,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,sEAAsE;AACtE;IACI,WAAW,EAAE,yDAAyD;IACtE,sCAAsC;IACtC,wCAAwC;IACxC,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".otp-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    margin: auto;\n}\n\n\n.otp-form {\n    width: 300px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.otp-form button:disabled {\n    background-color: #cccccc; /* Light grey background */\n    color: #666666; /* Darker grey text */\n    border: 1px solid #999999; /* Grey border */\n    cursor: not-allowed; /* Shows a 'not-allowed' cursor on hover */\n  }\n\n.otp-fields {\n    display: flex;\n    gap: 15px;\n    height: 30px;\n    justify-content: center;\n    margin-top: 50px;\n}\n\n.no-otp-msg {\n    font-size: small;\n}\n\n/* Assuming your button is inside a form with the class \"login-form\" */\n.otp-form button {\n    width: 100%; /* Set the button width to 100% of its parent container */\n    background-color: var(--primary-color);\n    border: 1px solid var(--secondary-color);\n    color: white;\n    height: 40px;\n    margin-top: 20px;\n    font-size: medium;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
