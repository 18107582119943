import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../styles/warranty.css'

const SystemSelect = () => {
    // to navigate to orders list page ...
  

   



    return (
        <div className='systemSelect'>
            
                <>
                    <div className='banner'>
                        {/*<img src={zyros_logo} className='logo' />*/}
                    </div>
                    <h1 className='title'>الإرجاع والضمان</h1>
                    {/* User info should probably come from userData, hardcoded for example */}
                    <div className='userInfo'>
                        {/*  <img src="/path-to-user-avatar.jpg" alt="User Avatar" className={styles.avatar} />*/}
                    </div>
                    <div className="pageLink">
                       <Link className="links" to='return/login'><button>الإرجاع</button></Link> 
                       <Link className="links" to='warranty/login'><button>الضمان</button></Link>
                    </div>
                
                </>
            
        </div>
    );
};

export default SystemSelect;